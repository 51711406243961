/* Bourbon */
@font-face {
  font-family: "asap-regular-webfont";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/asap-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/asap-regular-webfont.woff2") format("woff2"), url("../../fonts/webfonts/asap-regular-webfont.woff") format("woff"), url("../../fonts/webfonts/asap-regular-webfont.ttf") format("truetype"), url("../../fonts/webfonts/asap-regular-webfont.svg#asap-regular-webfont") format("svg");
}

@font-face {
  font-family: "asap-bold-webfont";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/asap-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/asap-bold-webfont.woff2") format("woff2"), url("../../fonts/webfonts/asap-bold-webfont.woff") format("woff"), url("../../fonts/webfonts/asap-bold-webfont.ttf") format("truetype"), url("../../fonts/webfonts/asap-bold-webfont.svg#asap-bold-webfont") format("svg");
}

@font-face {
  font-family: "sauna-black-webfont";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/sauna-black-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/sauna-black-webfont.woff2") format("woff2"), url("../../fonts/webfonts/sauna-black-webfont.woff") format("woff"), url("../../fonts/webfonts/sauna-black-webfont.ttf") format("truetype"), url("../../fonts/webfonts/sauna-black-webfont.svg#sauna-black-webfont") format("svg");
}

@font-face {
  font-family: "sauna-roman-webfont";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/sauna-roman-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/sauna-roman-webfont.woff2") format("woff2"), url("../../fonts/webfonts/sauna-roman-webfont.woff") format("woff"), url("../../fonts/webfonts/sauna-roman-webfont.ttf") format("truetype"), url("../../fonts/webfonts/sauna-roman-webfont.svg#sauna-roman-webfont") format("svg");
}

@font-face {
  font-family: "sugarpie-webfont";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/sugarpie-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/sugarpie-webfont.woff2") format("woff2"), url("../../fonts/webfonts/sugarpie-webfont.woff") format("woff"), url("../../fonts/webfonts/sugarpie-webfont.ttf") format("truetype"), url("../../fonts/webfonts/sugarpie-webfont.svg#sugarpie-webfont") format("svg");
}

#menu nav ul li a:hover, #menu nav ul li a.active {
  background-color: #1ca7e0;
  color: #ffffff;
}

/* Bourbon */
.clearfix, .product-list, .product-list h1, .weekly-offers .weekly-offers-product-list, .filter-list .panel-group .panel-sldier, .shoppie-total, .product-reviews h1.subtitle, .customer-address .form-group, .customer-profile .form-group, .list-panel, .list-panel .list-header, .list-panel .panel, .widget-bar, .header-logo, .message-popup .messages .message,
.group {
  zoom: 1;
}

.clearfix:before, .product-list:before, .product-list h1:before, .weekly-offers .weekly-offers-product-list:before, .filter-list .panel-group .panel-sldier:before, .shoppie-total:before, .product-reviews h1.subtitle:before, .customer-address .form-group:before, .customer-profile .form-group:before, .list-panel:before, .list-panel .list-header:before, .list-panel .panel:before, .widget-bar:before, .header-logo:before, .message-popup .messages .message:before, .clearfix:after, .product-list:after, .product-list h1:after, .weekly-offers .weekly-offers-product-list:after, .filter-list .panel-group .panel-sldier:after, .shoppie-total:after, .product-reviews h1.subtitle:after, .customer-address .form-group:after, .customer-profile .form-group:after, .list-panel:after, .list-panel .list-header:after, .list-panel .panel:after, .widget-bar:after, .header-logo:after, .message-popup .messages .message:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after, .product-list:after, .product-list h1:after, .weekly-offers .weekly-offers-product-list:after, .filter-list .panel-group .panel-sldier:after, .shoppie-total:after, .product-reviews h1.subtitle:after, .customer-address .form-group:after, .customer-profile .form-group:after, .list-panel:after, .list-panel .list-header:after, .list-panel .panel:after, .widget-bar:after, .header-logo:after, .message-popup .messages .message:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.icheckbox_minimal-grey,
.iradio_minimal-grey {
  background-color: white;
  background-image: url("../../img/iCheck/minimal/grey.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_minimal-grey,
  .iradio_minimal-grey {
    background-image: url("../../img/iCheck/minimal/grey@2x.png");
  }
}

.icheckbox_flat-orange,
.iradio_flat-orange {
  background-color: white;
  background-image: url("../../img/iCheck/flat/orange.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_flat-orange,
  .iradio_flat-orange {
    background-image: url("../../img/iCheck/flat/orange@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.white-popup {
  max-width: 600px;
  background-color: white;
  margin: 10px auto;
  font-family: helvetica, arial, sans-serif;
}

.mfp-close {
  cursor: pointer;
  z-index: 1;
}

.transparent-popup {
  max-width: 600px;
  background-color: transparent;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.uno-mfp-popup {
  max-width: 510px;
  margin: 0 auto;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 5px;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-color: #f0e5dc;
  height: 35px;
}

.select2-container--default .select2-selection--single[aria-expanded="true"] {
  border-color: #887975;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px;
}

.select2-results__option {
  padding: 10px;
}

.select2-container--open .select2-dropdown--below {
  border-color: #887975;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

.slick-slider {
  margin-bottom: 0;
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
  margin: 0;
  z-index: 1;
}

.slick-arrow:before {
  color: #62534f;
  font-family: FontAwesome;
  font-size: 22px;
}

.slick-arrow.slick-prev {
  left: 10px;
  right: auto;
}

.slick-arrow.slick-prev:before {
  content: '\f104';
}

.slick-arrow.slick-next {
  right: 10px;
}

.slick-arrow.slick-next:before {
  content: '\f105';
}

.slick-dots {
  position: absolute;
  bottom: 0;
  left: 10px;
  width: auto;
  z-index: 0;
  margin: 0 0 60px;
  display: inline-block;
}

.dots-top-right .slick-dots {
  left: auto;
  right: 10px;
  bottom: auto;
  top: 10px;
  width: auto;
}

.slick-dots li {
  width: auto !important;
  height: auto !important;
}

.slick-dots li button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.slick-dots li button:before {
  content: none !important;
}

.slick-dots li.slick-active button, .slick-dots li button:hover {
  background-color: white;
  opacity: 1;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus, .product .col-btn button:focus, .product-detail .product-info .col-btn button:focus, .suggestion-popup .col-btn button:focus {
  outline: none;
}

body {
  color: #62534f;
  font: normal 12px "asap-regular-webfont", sans-serif;
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid #f3a200;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "asap-bold-webfont";
  line-height: 1;
}

h1 {
  font: normal 36px "asap-bold-webfont", sans-serif;
  margin: 0 0 30px;
}

h2 {
  font: normal 24px "asap-bold-webfont", sans-serif;
  margin: 0 0 25px;
}

h3 {
  font: normal 18px "asap-bold-webfont", sans-serif;
  margin: 0 0 15px;
}

h4 {
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin: 0 0 10px;
}

h5 {
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin: 0 0 5px;
}

p {
  margin: 0 0 20px;
  font: normal 12px "asap-regular-webfont", sans-serif;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: inherit;
  display: inline-block;
}

a:hover, a:focus {
  text-decoration: none;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  color: #62534f;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

main:before {
  transition: opacity .3s;
  content: '';
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -5;
  opacity: 0;
}

main.has-layer:before {
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  opacity: 1;
}

header {
  /*display: table-row;
  height: 1px;*/
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height, .row-md-same-height, .row-sm-same-height, .row-xs-same-height {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: middle;
}

/*revert back to normal in md*/
@media (max-width: 991px) {
  .row-md-same-height {
    display: block;
  }
}

@media (max-width: 767px) {
  .row-sm-same-height {
    display: block;
  }
}

@media (max-width: 319px) {
  .row-xs-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 319px) {
  .col-xs-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 767px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 991px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn, .product .col-btn button, .product-detail .product-info .col-btn button, .suggestion-popup .col-btn button {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #f3a200;
  color: #f3a200;
  display: inline-block;
  height: 40px;
  outline: none;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 0.5px;
  line-height: 40px;
  transition: all 0.3s ease;
}

.btn:hover, .product .col-btn button:hover, .product-detail .product-info .col-btn button:hover, .suggestion-popup .col-btn button:hover, .btn:focus, .product .col-btn button:focus, .product-detail .product-info .col-btn button:focus, .suggestion-popup .col-btn button:focus {
  background: #f3a200;
  border-color: #f3a200;
  color: #fff;
  text-decoration: none;
}

.btn:active, .product .col-btn button:active, .product-detail .product-info .col-btn button:active, .suggestion-popup .col-btn button:active {
  background: #c08000;
  border-color: #c08000;
}

.btn-primary {
  background-color: #f3a200;
  border-color: #f3a200;
  color: #fff;
}

.btn-primary:active, .btn-primary:hover, .btn-primary:focus {
  background: #da9100 !important;
  border-color: #da9100 !important;
  color: #fff;
}

.btn-primary:active {
  background: #c08000;
  border-color: #c08000;
  color: #fff;
}

.btn-secondary {
  background-color: #12ca72;
  border-color: #12ca72;
  color: #fff;
}

.btn-secondary:hover, .btn-secondary:focus {
  background: #10b365;
  border-color: #10b365;
  color: #fff;
}

.btn-secondary:active {
  background: #0e9b58;
  border-color: #0e9b58;
  color: #fff;
}

.btn-tertiary {
  background-color: #62534f;
  border-color: #62534f;
  color: #fff;
}

.btn-tertiary:hover, .btn-tertiary:focus {
  background: #544744;
  border-color: #544744;
  color: #fff;
}

.btn-tertiary:active {
  background: #463b38;
  border-color: #463b38;
  color: #fff;
}

.btn-o {
  border-color: transparent;
  background-color: transparent;
}

.btn-o:hover, .btn-o:focus {
  background: transparent;
  border-color: transparent;
}

.btn-o:active {
  background: transparent;
  border-color: transparent;
}

.btn-long {
  padding: 0 60px;
}

@media (max-width: 991px) {
  .btn-sm-small {
    padding: 0 15px;
  }
}

.btn-lg {
  font-size: 1.4em;
  height: 60px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 28px;
  padding: 0 10px;
  line-height: 1;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #f3a200;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #f3a200;
  text-decoration: underline;
}

.btn-full {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .btn, .product .col-btn button, .product-detail .product-info .col-btn button, .suggestion-popup .col-btn button {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  border: 0;
  border-bottom: 1px solid #f0e5dc;
  height: 40px;
  outline: none;
  padding: 5px 10px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #f3a200;
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 120px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.form-group .radio-inline {
  margin-bottom: 5px;
  margin-left: 10px;
}

.form-group .radio-inline .iradio_minimal-grey {
  margin-right: 5px;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert .close {
  right: 0;
}

#menu nav ul li a:hover, #menu nav ul li a.active {
  background-color: #1ca7e0;
  color: #ffffff;
}

#menu {
  padding: 0;
}

#menu nav {
  float: right;
}

#menu nav ul {
  list-style: none;
  position: relative;
  float: left;
  padding: 0;
  margin: 30px 0 30px 15px;
}

#menu nav ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#menu nav ul li a {
  color: #004161;
  display: block;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

#menu nav ul li:hover > ul {
  display: block;
}

#menu nav ul li ul {
  color: white;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  z-index: 99999;
  padding-top: 35px;
  margin: 0;
}

#menu nav ul li ul li {
  float: none;
  width: 200px;
}

#menu nav ul li ul li a {
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  background-color: rgba(28, 167, 224, 0.9);
  color: white;
  padding: 10px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#menu nav ul li ul li:hover > a {
  background-color: #005F90;
}

#menu nav ul li ul li ul {
  top: 0;
  right: 100%;
  padding: 0;
  text-indent: 3px;
  font-size: 13px;
}

#mobile-category-menu, #mobile-menu {
  position: fixed;
  overflow-y: auto;
  height: 100%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#mobile-menu {
  width: 450px;
  background-color: #62534f;
  right: 0;
  top: 0;
  -webkit-transform: translate(100%);
  transform: translate(100%);
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 1px;
  opacity: 0;
}

@media (max-width: 767px) {
  #mobile-menu {
    width: 220px;
  }
}

#mobile-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-menu ul a {
  padding: 10px 20px;
  border-bottom: 1px solid #887975;
  display: block;
}

#mobile-menu ul a:focus, #mobile-menu ul a:active, #mobile-menu ul a:hover {
  color: #ffffff;
}

#mobile-menu-back {
  padding: 22px 20px;
  border-bottom: 1px solid #887975;
  display: block;
}

#mobile-menu-back:focus, #mobile-menu-back:active, #mobile-menu-back:hover {
  color: #ffffff;
}

#mobile-menu-back > * {
  line-height: 25px;
  vertical-align: middle;
}

#mobile-menu-back .fa {
  font-size: 26px;
  margin-right: 10px;
}

#mobile-category-menu {
  left: 0;
  top: 0;
  width: 100%;
  max-width: 520px;
  overflow-x: hidden;
  -webkit-transform: translate(-100%);
  transform: translate(-100%);
  opacity: 0;
}

.mobile-category-menu-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  white-space: nowrap;
  width: 100%;
  transition: all 0.5s ease;
  transition-delay: 0.4s;
}

.level-3-active .mobile-category-menu-header {
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.mobile-category-menu-header > * {
  display: inline-block;
  vertical-align: middle;
}

.mobile-category-header-title {
  padding: 0 15px;
  line-height: 70px;
  margin: 0;
}

#mobile-category-menu-back, .mobile-category-2-sub-content .mobile-category-3-sub-back {
  padding: 22px 15px;
  text-align: center;
}

#mobile-category-menu-back:focus, .mobile-category-2-sub-content .mobile-category-3-sub-back:focus, #mobile-category-menu-back:active, .mobile-category-2-sub-content .mobile-category-3-sub-back:active, #mobile-category-menu-back:hover, .mobile-category-2-sub-content .mobile-category-3-sub-back:hover {
  color: #887975;
}

#mobile-category-menu-back > *, .mobile-category-2-sub-content .mobile-category-3-sub-back > * {
  line-height: 25px;
  vertical-align: middle;
}

#mobile-category-menu-back .fa, .mobile-category-2-sub-content .mobile-category-3-sub-back .fa {
  font-size: 26px;
}

.mobile-category-cont {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-top: 70px;
}

.mobile-category-tab-group {
  transition: all 0.5s ease;
  position: relative;
  transition-delay: 0.3s;
}

.level-3-active .mobile-category-tab-group {
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  max-height: 100%;
}

.mobile-category-tab-cont {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition: all 0.5s ease;
  position: absolute;
  top: 380px;
  left: 0;
  z-index: 2;
}

.mobile-category-nav-tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.mobile-category-nav-tabs li {
  display: inline-block;
  margin-left: -2px;
}

.mobile-category-nav-tabs li.active a {
  background: #f3a200;
}

.mobile-category-nav-tabs a {
  padding: 15px 50px;
  line-height: 50px;
  font: normal 15px "asap-bold-webfont", sans-serif;
  background: #eadcd3;
  transition: all 0.5s ease;
}

.mobile-category-nav-tabs a:focus, .mobile-category-nav-tabs a:active, .mobile-category-nav-tabs a:hover {
  background: #f3a200;
  color: #62534f;
}

.mobile-category-tab-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  padding-left: 50px;
  overflow: hidden;
  max-height: 100%;
}

.mobile-category-tab-content.active {
  pointer-events: auto;
  visibility: visible;
  overflow-y: auto;
  max-height: none;
}

.mobile-category-2-sub-cont {
  padding: 0 0 0 50px;
  position: relative;
}

.mobile-category-2-sub {
  border: 1px solid #f0e5dc;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.mobile-category-2-sub:nth-child(even) {
  border-left: none;
  margin-left: -1px;
}

.mobile-category-2-sub:nth-child(n+3) {
  border-top: none;
}

.mobile-category-2-sub:before {
  content: '';
  padding-top: 100%;
  display: block;
}

.mobile-category-2-sub span.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.mobile-category-2-sub span.centered span {
  display: block;
}

.mobile-category-2-sub img {
  margin-bottom: 5px;
}

.mobile-category-2-sub-content {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  transition: all 0.5s ease;
  transition-delay: 0s;
  opacity: 0;
}

.mobile-category-2-sub-content.active {
  left: 0;
  transition: all 0.5s ease;
  transition-delay: 0.2s;
  opacity: 1;
}

.mobile-category-2-sub-content .mobile-category-sub-header > * {
  display: inline-block;
  vertical-align: middle;
}

.mobile-category-3-sub {
  padding: 0 15px;
  font: normal 15px "asap-bold-webfont", sans-serif;
  background-color: #f3a200;
  letter-spacing: 1px;
  line-height: 48px;
  border-bottom: 1px solid #f0e5dc;
  position: relative;
  padding-right: 55px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: block;
  color: #62534f;
}

.mobile-category-3-sub:focus, .mobile-category-3-sub:active, .mobile-category-3-sub:hover {
  color: #62534f;
}

.mobile-category-3-sub[data-toggle="collapse"]:after {
  content: '\f106';
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  top: 0;
  line-height: 48px;
  width: 48px;
  right: 5px;
  text-align: center;
}

.mobile-category-3-sub[data-toggle="collapse"].collapsed:after {
  content: '\f107';
}

.mobile-category-3-sub-content .panel {
  margin: 0;
  border: none;
  border-radius: 0;
}

.mobile-category-4-sub-content a {
  display: block;
  padding: 10px 15px;
  font: normal 14px "asap-regular-webfont", sans-serif;
  background-color: #eadcd3;
  letter-spacing: 1px;
  border-bottom: 1px solid #f0e5dc;
}

#menu nav ul li a:hover, #menu nav ul li a.active {
  background-color: #1ca7e0;
  color: #ffffff;
}

.group-type-list {
  background-color: #ffffff;
}

.group-type-list .list {
  /*bottom: 1px solid #f0e5dc;*/
}

.group-type-list .tab-cont {
  border-bottom: 1px solid #f0e5dc;
}

.group-type-list .nav-tabs {
  text-align: center;
  border: 0;
  padding: 0;
  margin-left: 3px;
}

.group-type-list .nav-tabs li {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0 -4px;
}

.group-type-list .nav-tabs li.active a {
  z-index: 1;
  color: #62534f;
}

.group-type-list .nav-tabs a {
  border: 1px solid #f0e5dc;
  border-bottom: 0;
  position: relative;
  border-radius: 10px 10px 0 0;
  -webkit-transform: perspective(5px) rotateX(0.5deg);
  transform: perspective(5px) rotateX(0.5deg);
  background-color: #ffffff;
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 1px;
}

.group-type-list .nav-tabs a:hover {
  color: #62534f;
}

.group-type-list .tab-content .group {
  border: 1px solid #f0e5dc;
  border-top: 0;
  border-right: 0;
  text-align: center;
  color: #62534f;
  font: normal 11px "asap-bold-webfont", sans-serif;
  position: relative;
  padding: 0;
}

.group-type-list .tab-content .group:last-child {
  border-right: 1px solid #f0e5dc;
}

.group-type-list .tab-content .group:hover {
  border-bottom-color: white;
}

.group-type-list .tab-content .group:hover .dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 1px;
}

@media (max-width: 767px) {
  .group-type-list .tab-content .group {
    border: 0;
    border-bottom: 1px solid #f0e5dc;
    border-right: 0 !important;
    padding: 15px 0;
  }
}

.group-type-list .tab-content .group > a {
  margin: 0;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 0.5px;
  display: block;
  line-height: 1;
  padding: 10px 15px;
}

.group-type-list .tab-content .group > a > img {
  margin: 0 auto 8px;
  display: block;
}

.group-type-list .tab-content .group > a.offer {
  color: #ff0134;
}

.group-type-list .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 720px;
  background-color: white;
  padding: 15px;
  z-index: 1;
  text-align: left;
  transition: all 0.5s ease;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 991px) {
  .group-type-list .dropdown {
    min-width: 320px;
  }
}

.group-type-list .dropdown.right {
  left: auto;
  right: 0;
}

.group-type-list .dropdown h3 {
  color: #62534f;
  font: normal 24px "asap-bold-webfont", sans-serif;
  font-size: 20px;
}

.group-type-list .dropdown h4 {
  color: #62534f;
  font: normal 18px "asap-bold-webfont", sans-serif;
  margin: 0 0 10px;
  font-size: 14px;
}

.group-type-list .dropdown h4 a {
  text-decoration: underline;
}

.group-type-list .dropdown .sub {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  display: block;
  line-height: 1.5;
  font-size: 12px;
}

.group-type-list .dropdown .sub-list .col-sm-6 {
  margin-bottom: 15px;
}

.grocer-check-bar {
  position: relative;
}

@media (max-width: 767px) {
  .grocer-check-bar {
    margin-bottom: 30px;
  }
}

.grocer-check-bar input[name=keyword] {
  border-radius: 15px;
  background: #faf6f4;
  border: 0;
  margin-bottom: 0;
  width: 100%;
  display: block;
  padding: 10px 40px 10px 20px;
  height: 30px;
}

.grocer-check-bar button[type=submit] {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  margin: auto;
  box-shadow: none;
  background: none;
  border: none;
  color: #f3a200;
}

.grocer-check-bar button[type=submit] i.fa {
  font-size: 14px;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #fdf1d9;
  border-top: none;
}

.category-list > li + li a {
  border-top: 1px solid #fdf1d9;
}

.category-list > li a {
  text-transform: uppercase;
  padding: 15px 25px;
  background-color: #f2f2f2;
  color: #ffb700;
  font: normal 11px "asap-bold-webfont", sans-serif;
  display: block;
}

.category-list > li a:hover {
  color: #ffb700;
  font-weight: bold;
  background-color: #ffffff;
}

.group-list {
  background-color: #ffffff;
}

@media (max-width: 991px) {
  .group-list {
    margin-bottom: 30px;
  }
}

.group-list img {
  max-width: 100%;
}

.group-list ul {
  padding: 15px 0;
  margin: 0;
  list-style: none;
}

.group-list li.active a {
  padding: 20px 35px;
  margin: 0 -15px;
  background-color: #ffb700;
  color: #ffffff;
}

.group-list li.active a:after {
  opacity: 1;
}

.group-list li + li {
  padding-top: 5px;
}

.group-list a {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  position: relative;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  transition: all 0.5s ease;
}

.group-list a:after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 7.5px solid transparent;
  border-left: 7.5px solid #ffb700;
  border-top: 7.5px solid transparent;
  display: block;
  transition: all 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (max-width: 991px) {
  .group-list a:after {
    content: none;
  }
}

.group-list a:hover {
  padding: 20px 35px;
  margin: 0 -15px;
  background-color: #ffb700;
  color: #ffffff;
}

.product-list .more {
  float: right;
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding-top: 25px;
}

.product-list .more:after {
  content: '\f101';
  font-family: FontAwesome;
  font-size: 14px;
  margin-left: 5px;
}

.option {
  position: relative;
}

.option:after {
  content: '\f0d7';
  font-family: FontAwesome;
  font-size: 12px;
  position: absolute;
  top: 20px;
  right: 14px;
  display: block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-top: 3px;
}

.option select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.5s ease;
  width: 100%;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #f0e5dc;
  padding: 0 30px 0 10px;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.option select:active, .option select:focus, .option select:hover {
  border-color: #62534f;
}

.product .img-cont {
  position: relative;
  background-size: contain;
  display: block;
}

.product .img-cont .icon {
  position: absolute;
  bottom: 0;
  left: 0;
}

.product .img-cont .icon img {
  max-width: 30px;
  float: left;
  margin-right: 5px;
}

.product .img-cont .discount {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 5px 2px;
  color: #fff;
  background: #e07348;
  font: normal 12px "asap-regular-webfont", sans-serif;
  width: 50px;
  text-align: center;
}

.product .img-cont .discount span {
  position: relative;
  z-index: 1;
}

.product .img-cont .discount:after {
  position: absolute;
  top: 8px;
  content: '';
  display: block;
  left: 4px;
  height: 41px;
  width: 42px;
  background: #e07348;
  border-radius: 0 10px 10px 10px;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.product .img-cont .group-icon {
  position: absolute;
  top: -15px;
  right: -15px;
}

.product .img-cont .group-icon img {
  max-width: 50px;
}

.product .img-cont:before {
  padding-top: 100%;
}

.product h3 {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product p {
  margin: 0;
  line-height: 1.4;
}

.product .price {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: block;
}

.product .unit {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.product .price-strike {
  text-decoration: line-through;
  color: #ff0134;
  font: normal 13px "asap-bold-webfont", sans-serif;
}

.product .price-strike .visible-lg-inline-block {
  margin-left: 3px;
}

.product .quantity-control {
  width: 100%;
}

.product .review {
  color: #ffb700;
  line-height: 2;
  display: block;
}

.product .col-btn {
  padding-top: 4px;
}

.product .col-btn button {
  width: 100%;
  height: 35px;
  padding: 0;
  border: 0;
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  line-height: 40px;
}

.product .col-btn button.btn-fav {
  background-color: #ff0134;
  position: relative;
  background-image: url("../../img/uno-shoppe-v3/icon_favourite.png");
  background-position: center;
  background-repeat: no-repeat;
}

.product .col-btn button.btn-fav:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: 0;
  transition: opacity .3s;
}

.product .col-btn button.btn-fav:hover:after, .product .col-btn button.btn-fav.favourited:after {
  opacity: 0.5;
}

.product .col-btn button.btn-fav img {
  margin: auto;
}

.product .col-btn button.btn-buy {
  background-color: #f3a200;
}

.product .col-btn button.btn-buy:after {
  content: '\f07a';
  font-family: FontAwesome;
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
  line-height: 1;
}

.product .col-btn button.btn-cancel {
  background-color: #ff0134;
}

.product .col-btn button.btn-cancel:after {
  content: '\f00d';
  font-family: FontAwesome;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.product .points {
  margin-top: 10px;
  color: #f3a200;
  font: normal 12px "asap-regular-webfont", sans-serif;
}

.product.grid {
  margin-bottom: 15px;
  position: relative;
}

.product.grid.col-tiny {
  margin-bottom: 4px;
}

@media (min-width: 1200px) {
  .product.grid.col-lg-2-5 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .product.grid.col-lg-2-6 {
    width: 16.667%;
    float: left;
  }
}

.simplified .product.grid > div {
  padding: 20px;
  width: 100%;
}

.actionable .product.grid {
  cursor: pointer;
}

@media (max-width: 767px) {
  .half-hidden .product.grid {
    /*height: auto !important;*/
  }
}

.half-hidden .product.grid > div {
  padding: 20px 20px 0;
  width: 100%;
  transition: width 0.5s, height 0.3s, padding 0.5s ease;
}

@media (max-width: 480px) {
  .half-hidden .product.grid > div {
    height: 100% !important;
  }
}

.half-hidden .product.grid .review {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  height: 0;
}

@media (max-width: 767px) {
  .half-hidden .product.grid .review {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.half-hidden .product.grid .col-content {
  margin: 0 0 20px;
  padding: 0;
}

.half-hidden .product.grid .col-action {
  height: 0;
  overflow: hidden;
  z-index: 2;
}

@media (max-width: 767px) {
  .half-hidden .product.grid .col-action {
    padding: 0;
    margin: 20px 0;
    height: auto !important;
    position: static;
    -webkit-transform: none;
    transform: none;
  }
}

.half-hidden .product.grid:hover {
  z-index: 1;
}

.half-hidden .product.grid:hover > div {
  padding: 20px 20px 0;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 110%;
  transition: width 0.5s, height 0.5s, padding 0.5s ease;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .half-hidden .product.grid:hover > div {
    width: 100%;
    height: 100% !important;
    box-shadow: none;
    position: static;
    left: 0;
    -webkit-transform: none;
    transform: none;
  }
}

.half-hidden .product.grid:hover .review {
  visibility: visible;
  opacity: 1;
  height: 24px;
}

.half-hidden .product.grid:hover .col-action {
  transition: all 0.5s ease;
}

.product.grid > div {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 20px 130px;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 480px) {
  .product.grid > div {
    padding: 10px 10px 130px;
  }
}

.product.grid .img-cont {
  position: relative;
  /*margin: -20px -20px 0;*/
  margin: 0;
}

.product.grid .col-action {
  position: absolute;
  bottom: 15px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 100%;
  padding: 0 20px;
}

.product.grid .col-action > div + div {
  margin-top: 5px;
}

.product.grid .col-unavailable {
  position: absolute;
  top: 30%;
  width: 100%;
  left: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
}

.product.list {
  width: 100% !important;
  height: auto !important;
}

.product.list + .list {
  padding-top: 15px;
  border-top: 1px solid #f0e5dc;
  margin-top: 15px;
}

.product.list > div {
  display: table;
  width: 100%;
}

.product.list .img-cont, .product.list .col-action, .product.list .col-content {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
}

.product.list .img-cont {
  width: 120px;
}

.product.list .col-content {
  width: 55%;
}

.product.list .col-action {
  width: 25%;
  padding-right: 0;
}

.product.list .col-action .option + .quantity-control, .product.list .col-action .option + .option {
  margin-top: 4px;
}

.product.list .col-action .quantity-control {
  max-width: none;
}

.product.list h3 {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product.list p {
  margin: 0;
  line-height: 1.4;
}

.seasonal-food {
  background-color: #ffffff;
}

.seasonal-food .nav-tabs {
  border-bottom-color: #f3a200;
  margin: 0 30px;
}

.seasonal-food .nav-tabs a {
  border: 0 !important;
  padding: 30px 15px;
  background-color: transparent !important;
  margin-bottom: 0;
  transition: all 0.5s ease;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.seasonal-food .nav-tabs a:hover {
  color: #f3a200;
}

.seasonal-food .nav-tabs li.active a {
  color: #f3a200;
}

.seasonal-food .tab-content {
  position: relative;
  padding: 0;
}

.seasonal-food .tab-pane {
  width: 100%;
  padding: 0 15px;
}

.seasonal-food .slick-list {
  overflow: visible;
}

.seasonal-food .product-list {
  padding-top: 15px;
}

.seasonal-food .product {
  padding: 0 15px;
}

.seasonal-food .slick-arrow.slick-prev {
  left: -15px;
}

.seasonal-food .slick-arrow.slick-next {
  right: -15px;
}

.weekly-offers .col-md-10 {
  padding-right: 0;
}

@media (max-width: 991px) {
  .weekly-offers .col-md-10 {
    padding-right: 15px;
  }
}

.weekly-offers .weekly-offers-product-list {
  display: none;
  transition: all 0.5s ease;
}

.weekly-offers .weekly-offers-product-list.active {
  display: block;
}

.shoppie-sidebar {
  padding: 15px 10px;
  background-color: #ffffff;
}

.shoppie-sidebar .profile-pic {
  margin: 10px auto 0;
  display: block;
}

.shoppie-sidebar .tooltip-max {
  left: inherit !important;
  right: 0;
}

.filter-list {
  text-align: left;
}

.filter-list .label {
  color: #887975;
  font: normal 12px "asap-regular-webfont", sans-serif;
}

.filter-list .form-group {
  margin: 0;
}

.filter-list .form-control {
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.filter-list .panel-group {
  text-align: left;
  padding: 0;
  margin: 0;
}

.filter-list .panel-group + .panel-group {
  border-top: 1px solid #f0e5dc;
}

.filter-list .panel-group .panel-heading {
  padding: 0;
  border: 0;
}

.filter-list .panel-group .panel-heading + .panel-collapse > .panel-body {
  border: 0;
  padding: 5px 10px;
}

.filter-list .panel-group .panel-heading a[data-toggle="collapse"] {
  vertical-align: middle;
}

.filter-list .panel-group .panel-heading a[data-toggle="collapse"]:before {
  content: '\f0da';
  font-family: FontAwesome;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
  line-height: 16px;
}

.filter-list .panel-group .panel-heading a[data-toggle="collapse"][aria-expanded="true"]:before {
  content: '\f0d7';
}

.filter-list .panel-group .panel-content {
  padding: 5px 10px;
}

.filter-list .panel-group .panel-content a {
  display: block;
  color: #887975;
  font: normal 12px "asap-regular-webfont", sans-serif;
  line-height: 1.6;
}

.filter-list .panel-group .panel-collapse {
  padding: 0 10px;
}

.filter-list .panel-group .panel-body {
  border: 0;
  padding: 5px 10px 20px;
  transition: all 0.5s ease;
  border-radius: 5px;
  display: inline-block;
}

.filter-list .panel-group .panel-body:hover {
  background-color: #eadcd3;
}

.filter-list .panel-group .panel-sldier {
  padding: 5px 10px 15px;
}

.filter-list .panel-group .panel-sldier .min-price {
  float: left;
}

.filter-list .panel-group .panel-sldier .max-price {
  float: right;
}

.filter-list .panel-group .panel {
  border: 0;
  padding: 15px 0;
  box-shadow: none;
}

.filter-list .panel-group .panel-title {
  padding: 0 10px;
}

@media (max-width: 1200px) {
  .filter-list .panel-group .panel-title {
    font-size: 15px;
  }
}

.filter-list .panel-group .panel-title a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: 1.2;
}

.filter-list .panel-group .min-price, .filter-list .panel-group .max-price {
  color: #62534f;
  font: normal 11px "asap-bold-webfont", sans-serif;
}

.filter-list .reset {
  border: 0;
  background-color: transparent;
  color: #f3a200;
  font: normal 11px "asap-bold-webfont", sans-serif;
  margin-top: 10px;
}

.shoppie-util {
  margin-bottom: 30px;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.shoppie-util .product-listing-type {
  float: right;
}

@media (max-width: 480px) {
  .shoppie-util .product-listing-type {
    float: none;
    text-align: center;
  }
}

.shoppie-util .product-listing-type button {
  background-color: #eadcd3;
  border: 0;
  border-radius: 5px;
  transition: all 0.5s ease;
  line-height: 24px;
}

.shoppie-util .product-listing-type button.active, .shoppie-util .product-listing-type button:focus, .shoppie-util .product-listing-type button:active, .shoppie-util .product-listing-type button:hover {
  background-color: #ffb700;
  color: #ffffff;
}

.shoppie-util .product-listing-type button + button {
  margin-left: 5px;
}

h1.title .shoppie-util {
  float: right;
  margin: 5px 0 0;
  line-height: 40px;
}

.shoppie-util .label {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.shoppie-util .option {
  position: relative;
  display: inline-block;
}

.shoppie-util .option + .option, .shoppie-util .option + button {
  margin-left: 35px;
}

.shoppie-util select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: #eadcd3;
  border-radius: 5px;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding: 5px 30px 5px 5px;
}

.shoppie-util .product-listing-sort select {
  width: auto;
}

.disabled .quantity-control {
  pointer-events: none;
  opacity: 0.5;
}

.quantity-control .num-minus button:before {
  content: '\f068';
  font-family: FontAwesome;
  font-size: 8px;
}

.quantity-control .num-plus button:before {
  content: '\f067';
  font-family: FontAwesome;
  font-size: 8px;
}

.quantity-control .input-group-btn {
  transition: all 0.5s ease;
}

.quantity-control .input-group-btn.disabled button {
  pointer-events: none;
  color: rgba(136, 121, 117, 0.3);
}

.quantity-control .input-group-btn:first-child > .btn, .quantity-control .product .col-btn .input-group-btn:first-child > button, .product .col-btn .quantity-control .input-group-btn:first-child > button, .quantity-control .product-detail .product-info .col-btn .input-group-btn:first-child > button, .product-detail .product-info .col-btn .quantity-control .input-group-btn:first-child > button, .quantity-control .suggestion-popup .col-btn .input-group-btn:first-child > button, .suggestion-popup .col-btn .quantity-control .input-group-btn:first-child > button {
  margin-right: 0;
}

.quantity-control .input-group-btn button {
  width: 35px;
  height: 35px;
  padding: 0 5px;
  line-height: 1;
  background: transparent;
  border-color: #f0e5dc;
  color: #887975;
  transition: all 0.5s ease;
}

.quantity-control .input-group-btn button:before {
  transition: all 0.5s ease;
}

.quantity-control .input-group-btn button:hover, .quantity-control .input-group-btn button:active, .quantity-control .input-group-btn button:focus {
  border-color: #887975;
}

.quantity-control .input-group-btn button:hover:before, .quantity-control .input-group-btn button:active:before, .quantity-control .input-group-btn button:focus:before {
  margin-top: 2px;
}

.quantity-control .form-control {
  height: 35px;
  padding: 0;
  text-align: center;
  border: 1px solid #f0e5dc;
}

.quantity-control .form-control::-webkit-inner-spin-button, .quantity-control .form-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shoppie-table .credit-header {
  padding: 15px;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  background-color: transparent;
}

.redeem-wrapper {
  padding: 30px;
}

.redeem-wrapper .redeem-list {
  padding: 10px 0;
}

@media (max-width: 767px) {
  .redeem-wrapper .redeem-list {
    text-align: center;
  }
}

.shoppie-total {
  text-align: right;
  background-color: #ffffff;
  margin-top: 4px;
}

.shoppie-total > div:first-child {
  text-align: left;
}

.shoppie-total > div:last-child {
  text-align: right;
}

.shoppie-total .list-total {
  padding: 30px;
  text-align: center;
  display: table;
  width: 100%;
}

.shoppie-total .list-total + .list-total {
  border-top: 1px solid #f0e5dc !important;
}

.shoppie-total .list-total > div {
  display: table-cell;
  vertical-align: middle;
}

.shoppie-total .list-total .label {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 0.5px;
  width: 320px;
  white-space: normal;
}

@media (max-width: 480px) {
  .shoppie-total .list-total .label {
    width: 100%;
    text-align: left;
  }
}

.shoppie-total .list-total .label h4 {
  margin: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.shoppie-total .list-total .label h4 + h4 {
  margin-top: 5px;
}

.shoppie-total .list-total .amount {
  vertical-align: middle;
  text-align: right;
  padding-right: 0;
  display: table-cell;
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
  white-space: nowrap;
  padding-left: 10px;
}

@media (max-width: 767px) {
  .shoppie-total .list-total .amount {
    padding: 0;
  }
}

.shoppie-total .list-total .amount h4 {
  margin: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.shoppie-total .list-total .amount h4 + h4 {
  margin-top: 5px;
}

.shoppie-total .list-total .list-section {
  display: table;
  width: 100%;
}

.shoppie-total .sub-total .amount {
  font: normal 24px "asap-bold-webfont", sans-serif;
}

.shoppie-total h3 {
  margin: 0;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.shoppie-total .form-control {
  border: 0;
  background-color: #faf6f4;
  border-radius: 5px;
}

.shoppie-total td {
  padding: 30px 0 30px 0;
}

.shoppie-total .list-total-table {
  width: 100%;
}

.shoppie-total .list-total-table tr {
  border: none;
}

.shoppie-total .list-total-table tr td {
  padding: 0;
}

.shoppie-total .list-total-table tr + tr {
  padding-top: 10px;
}

.cart-list-summary .product {
  transition: all 0.5s ease;
  background-color: #ffffff;
  padding: 15px;
}

.cart-list-summary .product.disabled {
  opacity: 0.5;
}

.cart-list-summary .product-img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  width: 95px;
}

@media (max-width: 767px) {
  .cart-list-summary .product-img {
    padding: 0;
    text-align: center;
    width: 100%;
  }
}

.cart-list-summary .product-img img {
  display: inline-block !important;
}

@media (max-width: 480px) {
  .cart-list-summary .product-img img {
    text-align: center;
  }
}

.cart-list-summary .product-content {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .cart-list-summary .product-content {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
}

.cart-list-summary .product-content h4 {
  color: #ffb700;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin: 0;
}

.cart-list-summary .product-content p {
  margin: 0;
  text-align: left;
}

@media (max-width: 767px) {
  .cart-list-summary .product-content p {
    text-align: center;
  }
}

.cart-list-summary .quantity {
  padding: 0;
}

.cart-list-summary .quantity .quantity-control {
  padding: 15px;
  border-right: 1px solid #f0e5dc;
}

@media (max-width: 767px) {
  .cart-list-summary .quantity .quantity-control {
    border: 0;
    padding: 15px;
  }
}

.cart-list-summary .price {
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: inline-block;
}

.cart-list-summary .unit {
  color: #887975;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.cart-list-summary .gst {
  color: #887975;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.cart-list-summary .strike-price {
  color: #ff0134;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: line-through;
}

.cart-list-summary .close {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .cart-list-summary .product-unit-price, .cart-list-summary .product-total {
    text-align: center !important;
  }
}

.cart-list-summary .product-total {
  text-align: right;
}

.credit-list .credit {
  background-color: #ffffff;
  padding: 15px;
}

@media (max-width: 767px) {
  .credit-list .credit {
    text-align: center;
  }
}

.credit-list .credit + .credit {
  margin-top: 4px;
}

.credit-list .credit .credit-img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .credit-list .credit .credit-img {
    padding: 0;
    text-align: center;
    width: 100%;
  }
}

.credit-list .credit .credit-img img {
  display: inline-block !important;
}

@media (max-width: 767px) {
  .credit-list .credit .credit-img img {
    text-align: center;
  }
}

.credit-list .credit .credit-content {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .credit-list .credit .credit-content {
    display: block;
    padding: 10px 0;
  }
}

.credit-list .credit .credit-content p {
  margin: 0;
  text-align: left;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

@media (max-width: 767px) {
  .credit-list .credit .credit-content p {
    text-align: center;
  }
}

.credit-list .credit .amount {
  color: #ffb700;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-transform: uppercase;
}

.credit-list .credit > div:last-child {
  text-align: right;
}

@media (max-width: 767px) {
  .credit-list .credit > div:last-child {
    text-align: center;
  }
}

.credit-list .credit .quantity {
  padding: 0;
}

.credit-list .credit .quantity .quantity-control {
  border-right: 1px solid #f0e5dc;
  padding: 15px;
}

@media (max-width: 767px) {
  .credit-list .credit .quantity .quantity-control {
    border: 0;
    padding: 15px;
  }
}

.credit-list .credit .credit-unit-price, .credit-list .credit .credit-total {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

@media (max-width: 767px) {
  .credit-list .credit .credit-unit-price, .credit-list .credit .credit-total {
    text-align: center !important;
  }
}

.credit-list .credit .credit-total {
  text-align: right;
}

.row-action {
  margin: 30px 0;
  text-align: right;
}

@media (max-width: 767px) {
  .row-action {
    text-align: center;
  }
}

.row-action button + button {
  margin-left: 10px;
}

.cart-steps {
  margin-bottom: 30px;
}

.cart-steps .steps-table {
  display: table;
  width: calc(100% - 23px);
}

@media (max-width: 767px) {
  .cart-steps .steps-table {
    display: block;
  }
}

.cart-steps .step {
  background-color: #62534f;
  transition: all 0.5s ease;
  height: 39px;
  display: table-cell;
  text-align: center;
  padding: 10px 30px;
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  border-radius: 5px 0 0 5px;
  position: relative;
  white-space: nowrap;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .cart-steps .step {
    display: inline-block;
  }
}

.cart-steps .step.active {
  background-color: #ffb700;
}

.cart-steps .step.active:after {
  background-image: url("../../img/uno-shoppe-v3/arrow_yellow.png");
}

.cart-steps .step:after {
  content: '';
  background-image: url("../../img/uno-shoppe-v3/arrow_brown.png");
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  position: absolute;
  right: -23px;
  top: 0;
  width: 23px;
  height: 39px;
  z-index: 1;
}

.customer-signup, .form-signup {
  margin-top: 30px;
}

.customer-signup .forgot-password, .form-signup .forgot-password {
  text-decoration: underline;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: block;
  margin: -10px 0 10px;
  max-width: 150px;
}

.customer-signup .dob, .form-signup .dob {
  max-width: 300px;
}

.customer-signup .col-checkbox, .form-signup .col-checkbox {
  width: 20px;
}

.customer-signup .checkbox h4, .form-signup .checkbox h4 {
  margin-bottom: 5px;
}

.customer-signup .checkbox label, .form-signup .checkbox label {
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin-left: 5px;
}

.customer-signup label.btn, .form-signup label.btn {
  color: #fff;
  padding: 0 25px;
  line-height: 40px;
}

.cart-add-address .from-group-multi-column.has-error .has-error .error,
.cart-edit-address .from-group-multi-column.has-error .has-error .error {
  margin: 0 !important;
}

.cart-add-address .option.has-error .select2-container--default .select2-selection--single,
.cart-edit-address .option.has-error .select2-container--default .select2-selection--single {
  border: 1px solid red;
}

.cart-add-address .option.has-error span.error,
.cart-edit-address .option.has-error span.error {
  display: inline-block;
  margin-top: 10px;
}

.cart-add-address .option .select2-container--default .select2-selection--single,
.cart-edit-address .option .select2-container--default .select2-selection--single {
  background: #faf6f4;
  border: none;
  outline: none;
  height: 40px;
  line-height: 40px;
}

.cart-add-address .option .select2-container--default .select2-selection--single .select2-selection__arrow,
.cart-edit-address .option .select2-container--default .select2-selection--single .select2-selection__arrow {
  visibility: hidden;
}

.cart-add-address .option .select2-container--open .select2-selection--single,
.cart-edit-address .option .select2-container--open .select2-selection--single {
  border: 1px solid #f0e5dc !important;
}

.form-signup .form-signup-type input:checked + label {
  background-color: #f3a200;
}

.form-signup .form-signup-type label {
  padding: 10px 15px;
  background-color: #faf6f4;
  transition: all 0.5s ease;
  border-radius: 5px;
  cursor: pointer;
}

.form-signup .form-signup-type label:hover {
  background-color: #f3a200;
}

.form-signup .form-signup-panel {
  position: relative;
  pointer-events: none;
  display: none;
}

.form-signup .form-signup-panel .option .select2-container--default .select2-selection--single {
  background: #faf6f4;
  border: none;
  outline: none;
}

.form-signup .form-signup-panel .option .select2-container--default .select2-selection--single .select2-selection__arrow {
  visibility: hidden;
}

.form-signup .form-signup-panel .option .select2-container--open .select2-selection--single {
  border: 1px solid #f0e5dc !important;
}

.form-signup .form-signup-panel.in {
  position: relative;
  pointer-events: auto;
  display: block;
}

.guest-signup {
  margin-top: 30px;
}

.cart-address .choose-address h4 {
  margin-bottom: 5px;
}

.cart-address p {
  margin: 0;
}

.cart-address .address .tel {
  display: inline-block;
}

.cart-address .comment .form-control {
  background-color: white;
}

.cart-address .col-action {
  text-align: right;
}

.branch-bar {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 30px 0 0;
}

.branch-bar .branch-selection {
  padding: 10px;
  border: 1px solid #ccc;
}

.branch-bar + .cart-delivery .date-bar {
  margin-top: 15px;
}

.cart-delivery .date-bar {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 30px 0 15px;
}

.cart-delivery .date-bar .btn, .cart-delivery .date-bar .product .col-btn button, .product .col-btn .cart-delivery .date-bar button, .cart-delivery .date-bar .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .cart-delivery .date-bar button, .cart-delivery .date-bar .suggestion-popup .col-btn button, .suggestion-popup .col-btn .cart-delivery .date-bar button {
  border: 0;
  height: auto;
  line-height: 1;
  position: relative;
  padding: 5px 15px;
}

.cart-delivery .date-bar .prev:before {
  content: '\f104';
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 5px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cart-delivery .date-bar .next:after {
  content: '\f105';
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cart-delivery .date-bar .fa {
  color: #f3a200;
  margin-left: 5px;
}

.cart-delivery .date-bar input.delivery-date {
  text-align: center;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: inline-block;
}

.cart-delivery .content {
  padding: 5px;
  overflow-x: auto;
}

.cart-delivery .content.bottom {
  padding: 30px;
}

.cart-delivery .content.bottom h3 {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.cart-delivery .content.bottom a {
  color: #f3a200;
  letter-spacing: 0.5px;
}

.cart-delivery .content.bottom .price {
  text-align: right;
  white-space: nowrap;
  color: #ff0134;
  font: normal 18px "asap-bold-webfont", sans-serif;
}

.cart-delivery .col-action {
  text-align: right;
}

@media (max-width: 991px) {
  .cart-delivery .time-slot-wrapper {
    width: 1002px;
  }
}

.cart-delivery .time-slot .header {
  height: 80px;
}

.cart-delivery .time-slot .header:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.cart-delivery .time-slot .header > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.cart-delivery .time-slot .header h3 {
  margin: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.cart-delivery .time-slot .time {
  height: 100px;
  margin-bottom: 5px;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.cart-delivery .time-slot .time:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.cart-delivery .time-slot .time > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

@media (max-width: 991px) {
  .cart-delivery .time-slot .time {
    height: 80px;
  }
}

.cart-delivery .day-grid .header {
  text-align: center;
}

.cart-delivery .day-grid .date {
  height: 80px;
}

.cart-delivery .day-grid .date:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.cart-delivery .day-grid .date > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.cart-delivery .day-grid .date p {
  margin: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.cart-delivery .day-grid .grid {
  height: 100px;
  text-align: center;
}

@media (max-width: 991px) {
  .cart-delivery .day-grid .grid {
    height: 80px;
  }
}

.cart-delivery .day-grid .grid .grid-content {
  width: 100%;
  height: 100%;
  background-color: #eadcd3;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.cart-delivery .day-grid .grid .grid-content:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.cart-delivery .day-grid .grid .grid-content > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.cart-delivery .day-grid .grid .grid-content h3 {
  margin: 0 0 5px;
  color: #62534f;
  font: normal 13px "asap-bold-webfont", sans-serif;
  transition: all 0.5s ease;
}

.cart-delivery .day-grid .grid .grid-content p {
  margin: 0;
  transition: all 0.5s ease;
}

.cart-delivery .day-grid .grid .grid-content .link {
  color: #f3a200;
  font: normal 13px "asap-bold-webfont", sans-serif;
}

.cart-delivery .day-grid .grid .grid-content .amount {
  color: #62534f;
  font: normal 13px "asap-bold-webfont", sans-serif;
  transition: all 0.5s ease;
}

.cart-delivery .day-grid .grid:hover .grid-content, .cart-delivery .day-grid .grid.selected .grid-content {
  background-color: #f3a200;
}

.cart-delivery .day-grid .grid:hover .grid-content h3, .cart-delivery .day-grid .grid.selected .grid-content h3 {
  color: #ffffff;
}

.cart-delivery .day-grid .grid:hover .grid-content .amount, .cart-delivery .day-grid .grid.selected .grid-content .amount {
  color: #ffffff;
}

.cart-delivery .day-grid .grid.booked .grid-content {
  background-color: rgba(253, 241, 217, 0.5);
}

.cart-delivery .day-grid .grid.booked .grid-content h3 {
  color: #887975;
}

.cart-delivery .day-grid .day-row {
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .cart-payment {
    text-align: center;
  }
}

.cart-payment + .credit {
  margin-top: 4px;
}

.cart-payment .product {
  transition: all 0.5s ease;
  background-color: #ffffff;
  padding: 15px;
}

.cart-payment .product.disabled {
  opacity: 0.5;
}

.cart-payment .product-img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  width: 95px;
}

@media (max-width: 767px) {
  .cart-payment .product-img {
    padding: 0;
    text-align: center;
    width: 100%;
  }
}

.cart-payment .product-img img {
  display: inline-block !important;
}

@media (max-width: 767px) {
  .cart-payment .product-img img {
    text-align: center;
  }
}

.cart-payment .product-content {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .cart-payment .product-content {
    display: block;
    padding: 10px 0;
  }
}

.cart-payment .product-content h4 {
  color: #ffb700;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin: 0;
}

.cart-payment .product-content p {
  margin: 0;
  text-align: left;
}

@media (max-width: 767px) {
  .cart-payment .product-content p {
    text-align: center;
  }
}

.cart-payment .quantity {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.cart-payment .quantity > div {
  padding: 15px 0;
  border-right: 1px solid #f0e5dc;
}

.cart-payment .price {
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: inline-block;
}

.cart-payment .unit {
  color: #887975;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.cart-payment .gst {
  color: #887975;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.cart-payment .strike-price {
  color: #ff0134;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: line-through;
  display: inline-block;
}

.cart-payment .product-unit-price, .cart-payment .product-total {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

@media (max-width: 767px) {
  .cart-payment .product-unit-price, .cart-payment .product-total {
    text-align: center !important;
  }
}

.cart-payment .product-total {
  text-align: right;
}

.cart-payment .col-action {
  text-align: right;
}

.cart-payment .col-action button + button {
  margin-left: 5px;
}

@media (max-width: 991px) {
  .cart-payment .pay-credit .label {
    display: block;
    margin: 10px 0;
  }
}

@media (max-width: 991px) {
  .cart-payment .pay-credit .amount {
    display: block;
    margin: 10px 0;
  }
}

.cart-payment-method {
  padding: 30px;
  background-color: white;
  margin-top: 30px;
}

.payment-method-choice .choice {
  position: relative;
}

.payment-method-choice .choice .choice-header {
  padding: 10px;
}

.payment-method-choice .choice .choice-header h3 {
  margin: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
  line-height: 20px;
  font-size: 17px;
}

.payment-method-choice .choice .choice-header h3 > div {
  margin-right: 10px;
  top: -2px;
}

.payment-method-choice .choice .choice-body {
  padding: 0px 35px;
  display: none;
  border-bottom: 1px solid #F2F2F2;
}

.payment-method-choice .choice .choice-body input {
  width: 100%;
  max-width: 100%;
}

.payment-method-choice .choice .choice-body input.short {
  width: 100px;
}

.payment-method-choice .choice .choice-body .card_expiration > * {
  float: left;
}

.payment-method-choice .choice .choice-body .card_expiration > * + * {
  margin-left: 10px;
}

.payment-method-choice .choice .choice-body .card_expiration .option:after {
  top: 15px;
}

.payment-method-choice .choice .choice-body .card_expiration .slash {
  font-size: 35px;
  position: relative;
  top: -5px;
  height: 35px;
}

.payment-method-choice .choice .choice-body .card_expiration select {
  width: 80px;
}

.payment-method-choice .choice .choice-body .card_expiration select option[disabled] {
  color: #727272;
}

.payment-method-choice .choice .choice-body sup {
  display: inline-block;
  margin-right: 10px;
}

.payment-method-choice .choice.active .choice-body {
  display: block;
}

.voucher-popup {
  padding: 30px;
  border-radius: 5px;
  position: relative;
  max-width: 720px;
}

.voucher-popup .col-action {
  text-align: right;
  margin-top: 15px;
}

.vouchers {
  border-radius: 5px;
  background-color: #eadcd3;
  max-height: 350px;
  min-height: 250px;
}

@media (max-height: 600px) {
  .vouchers {
    max-height: 250px;
  }
}

.vouchers .clearfix, .vouchers .product-list, .vouchers .product-list h1, .product-list .vouchers h1, .vouchers .weekly-offers .weekly-offers-product-list, .weekly-offers .vouchers .weekly-offers-product-list, .vouchers .filter-list .panel-group .panel-sldier, .filter-list .panel-group .vouchers .panel-sldier, .vouchers .shoppie-total, .vouchers .product-reviews h1.subtitle, .product-reviews .vouchers h1.subtitle, .vouchers .customer-address .form-group, .customer-address .vouchers .form-group, .vouchers .customer-profile .form-group, .customer-profile .vouchers .form-group, .vouchers .list-panel, .vouchers .list-panel .list-header, .list-panel .vouchers .list-header, .vouchers .list-panel .panel, .list-panel .vouchers .panel, .vouchers .widget-bar, .vouchers .header-logo, .vouchers .message-popup .messages .message, .message-popup .messages .vouchers .message {
  padding: 15px;
}

.vouchers .voucher {
  margin-bottom: 5px;
  cursor: pointer;
}

.vouchers .voucher > div {
  height: 100%;
  background-color: white;
  position: relative;
  padding: 10px;
  border-radius: 5px;
}

.vouchers .voucher > div:before {
  content: '';
  font-family: FontAwesome;
  font-size: 24px;
  background-color: rgba(243, 162, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s ease;
  border-radius: 5px;
}

.vouchers .voucher > div:after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 42px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
}

.vouchers .voucher > div.active:hover:after {
  content: "\f00d" !important;
}

.vouchers .voucher > div.active:before, .vouchers .voucher > div.active:after, .vouchers .voucher > div:hover:before, .vouchers .voucher > div:hover:after {
  opacity: 1;
}

.vouchers .voucher h3 {
  margin-bottom: 10px;
}

.vouchers .voucher img {
  width: 100%;
  margin-bottom: 10px;
}

.product-detail .product-img .discount {
  position: absolute;
  top: 0;
  left: 120px;
  padding: 10px 5px 2px;
  color: #fff;
  background: #e07348;
  font: normal 25px "asap-regular-webfont", sans-serif;
  width: 80px;
  text-align: center;
}

.product-detail .product-img .discount span {
  position: relative;
  z-index: 1;
}

.product-detail .product-img .discount:after {
  position: absolute;
  top: 13px;
  content: '';
  display: block;
  left: 9px;
  height: 62px;
  width: 62px;
  background: #e07348;
  border-radius: 0 10px 10px 10px;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.product-detail .product-img .group-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.product-detail .product-img .group-icon img {
  max-width: 80px;
}

.product-detail .product-img-side {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 0;
  border-radius: 5px;
  /*&:before{ content:'\f110'; font: {family: FontAwesome; size: 26px; }
	  @include animation( fa-spin 1s infinite steps(8) ); display: block; z-index: 1;
	  position: fixed; top: 50%; left: 50%;
	  margin-left: -12.5px; margin-top: -15px; color: white;
	}
	&:after{ content:''; padding: 10px; background-color: rgba(black, 0.5); @include border-radius(5px);
		display: block; position: fixed; top: 50%; left: 50%; @include transform(translate(-50%, -50%));
	  width: 40px; height: 40px; @include transition(all 0.5s ease); opacity: 1;
	}*/
}

@media (max-width: 991px) {
  .product-detail .product-img-side {
    display: block;
    table-layout: auto;
    padding: 10px 10px 100px;
  }
}

.product-detail .product-img-side.ready {
  background-color: white;
}

.product-detail .product-img-side.ready:before, .product-detail .product-img-side.ready:after {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.product-detail .product-img-side > div {
  opacity: 0;
  transition: all 0.5s ease;
}

.product-detail #lens {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-size 0.2s, background-image 0.2s ease;
  background-repeat: no-repeat;
}

.product-detail .col-thumb {
  width: 100px;
  display: table-cell;
  cursor: pointer;
}

@media (max-width: 991px) {
  .product-detail .col-thumb {
    display: table;
    table-layout: fixed;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.product-detail .col-thumb .thumbs {
  max-height: 300px;
  overflow: hidden;
  padding: 0 10px;
}

@media (max-width: 991px) {
  .product-detail .col-thumb .thumbs {
    max-height: none;
    width: 100%;
    white-space: nowrap;
    display: block;
  }
}

.product-detail .col-thumb .img-cont {
  border: 1px solid #f0e5dc;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  padding: 0;
}

.product-detail .col-thumb .img-cont:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.product-detail .col-thumb ul {
  list-style: none;
  padding: 0;
  margin: 0 0 5px;
}

.product-detail .col-thumb ul li {
  position: relative;
}

@media (max-width: 991px) {
  .product-detail .col-thumb ul li {
    width: 80px;
    display: inline-block;
  }
}

.product-detail .col-thumb ul li + li {
  margin-top: 5px;
}

@media (max-width: 991px) {
  .product-detail .col-thumb ul li + li {
    margin: 0 0 0 5px;
  }
}

.product-detail .col-thumb ul li:before {
  content: '';
  transition: all 0.5s ease;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-detail .col-thumb ul li.slick-active:before {
  background-color: rgba(255, 255, 255, 0.8);
}

.product-detail .col-thumb .product-img-utility {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 40px;
  margin: auto;
  display: block;
}

@media (max-width: 991px) {
  .product-detail .col-thumb .product-img-utility {
    display: table-cell;
    vertical-align: middle;
  }
}

.product-detail .col-thumb .product-img-utility img {
  margin: auto;
}

.product-detail .product-img-slider-cont {
  width: 100%;
  display: table-cell;
  padding: 0 10px;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .product-detail .product-img-slider-cont {
    display: block;
  }
}

.product-detail .product-img-slider .img-main {
  text-align: center;
}

.product-detail .product-img-slider .img-main:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.product-detail .product-img-slider .img-main > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.product-detail .product-img-slider .img-main img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px;
}

.product-detail .quantity-control .input-group-btn button {
  width: 42px;
  height: 42px;
}

.product-detail .product-info .product-info-side {
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  height: 100%;
}

.product-detail .product-info p {
  margin: 0;
}

.product-detail .product-info .product-detail-price {
  padding: 30px 0;
}

.product-detail .product-info .product-detail-price .price {
  color: #62534f;
  font: normal 18px "asap-bold-webfont", sans-serif;
}

.product-detail .product-info .product-detail-price .strike-price {
  color: #ff0134;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: line-through;
}

.product-detail .product-info .product-detail-price .strike-price span {
  display: inline-block;
}

.product-detail .product-info .product-detail-reviews {
  margin-bottom: 25px;
}

.product-detail .product-info .product-detail-reviews .review {
  color: #ffb700;
  margin-bottom: 5px;
}

.product-detail .product-info .product-detail-reviews .review-total {
  color: #62534f;
  font: normal 13px "asap-bold-webfont", sans-serif;
}

.product-detail .product-info .product-option {
  margin-bottom: 5px;
}

.product-detail .product-info .quantity-control .form-control {
  height: 42px;
}

.product-detail .product-info .product-option {
  height: 42px;
}

.product-detail .product-info .col-btn {
  padding-top: 4px;
}

.product-detail .product-info .col-btn button {
  width: auto;
  padding: 0;
  border: 0;
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  line-height: 40px;
  height: 42px;
}

.product-detail .product-info .col-btn button.btn-fav {
  background-color: #ff0134;
  padding: 0 12px;
  position: relative;
}

.product-detail .product-info .col-btn button.btn-fav:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: 0;
  transition: opacity .3s;
}

.product-detail .product-info .col-btn button.btn-fav:hover:after, .product-detail .product-info .col-btn button.btn-fav.favourited:after {
  opacity: 0.5;
}

.product-detail .product-info .col-btn button.btn-fav img {
  margin: auto;
}

.product-detail .product-info .col-btn button.btn-buy {
  background-color: #f3a200;
  padding: 0 30px;
  margin-right: 10px;
}

.product-detail .product-info .col-btn .points {
  color: #f3a200;
  font: normal 13px "asap-bold-webfont", sans-serif;
  display: inline-block;
}

.magnifier-preview-cont {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.1s ease;
  -webkit-transform: translate(-10px);
  transform: translate(-10px);
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  border-radius: 5px;
}

.magnifier-preview-cont.active {
  z-index: 4;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .magnifier-preview-cont {
    display: none;
  }
}

.magnifier-preview {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-size 0.2s, background-image 0.2s ease;
  background-repeat: no-repeat;
  /*img{ max-width: none !important; /!*width: auto !important; height: auto !important;*!/ }*/
}

#social-share {
  margin: 30px 0;
}

.product-suggestion {
  margin: 30px 0;
}

.product-reviews {
  margin: 30px 0;
}

.product-reviews h1.subtitle .btn, .product-reviews h1.subtitle .product .col-btn button, .product .col-btn .product-reviews h1.subtitle button, .product-reviews h1.subtitle .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .product-reviews h1.subtitle button, .product-reviews h1.subtitle .suggestion-popup .col-btn button, .suggestion-popup .col-btn .product-reviews h1.subtitle button {
  float: right;
  margin-top: 3px;
}

.product-reviews .total-rating .fa, .product-reviews .ratings .fa {
  font-size: 16px;
  letter-spacing: 1px;
  color: #f3a200;
}

.product-reviews .total-rating .num {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.customer-order-details h1.title, .customer-favourites h1.title {
  margin-bottom: 10px;
}

.customer-order-details h1.title button, .customer-favourites h1.title button {
  float: right;
}

.customer-order-details .result, .customer-favourites .result {
  margin-bottom: 20px;
}

.customer-order-details .category_title, .customer-favourites .category_title {
  position: relative;
  font-size: 18px;
  overflow: hidden;
  z-index: 0;
}

.customer-order-details .category_title:after, .customer-favourites .category_title:after {
  content: '';
  border-top: 1px solid #f3a200;
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
}

.customer-order-details .category_title span, .customer-favourites .category_title span {
  background-color: #fff5eb;
  padding: 0 15px 0 0;
  position: relative;
  z-index: 1;
}

.customer-order-details .product.add-to-cart > div, .customer-favourites .product.add-to-cart > div {
  background-color: rgba(255, 255, 255, 0.7);
  transition: width 0.5s, height 0.3s, padding 0.5s, background 0.5s ease;
}

.customer-order-details .product.add-to-cart .img-cont, .customer-order-details .product.add-to-cart .col-content, .customer-favourites .product.add-to-cart .img-cont, .customer-favourites .product.add-to-cart .col-content {
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.customer-order-details .product-list-category-group, .customer-favourites .product-list-category-group {
  margin-bottom: 60px;
}

.customer-address .form-group {
  margin: 0 0 30px;
}

.customer-address label {
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding: 15px 0;
}

.customer-address textarea.form-control {
  background-color: #f2f2f2;
  border: 0;
  padding: 15px;
  border-radius: 5px;
  min-height: 90px;
  transition: background 0.5s, padding 0.3s ease;
}

.customer-address textarea.form-control:not([readonly]):hover, .customer-address textarea.form-control:focus, .customer-address textarea.form-control:active {
  background-color: #f2f2f2;
}

.customer-address textarea.form-control[readonly] {
  resize: none;
  background-color: white;
  padding: 15px 0;
}

.customer-address .delete {
  text-decoration: underline;
  color: #ff0134;
}

.customer-address .col-action {
  margin-top: 5px;
}

.customer-address .address .update, .customer-address .address .cancel {
  display: none;
}

.customer-address .address .cancel {
  padding: 0 5px;
}

.customer-address .address.editable .edit {
  display: none;
}

.customer-address .address.editable .update, .customer-address .address.editable .cancel {
  display: inline-block;
}

.create-customer-address,
.edit-customer-address {
  height: 100%;
  background-color: white;
  padding: 15px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.create-customer-address .form-control,
.edit-customer-address .form-control {
  max-width: none !important;
}

.create-customer-address .from-group-multi-column.has-error .has-error .error,
.edit-customer-address .from-group-multi-column.has-error .has-error .error {
  margin: 0 !important;
}

.create-customer-address .option,
.edit-customer-address .option {
  max-width: none !important;
  padding-bottom: 0 !important;
}

.create-customer-address .option.has-error .select2-container--default .select2-selection--single,
.edit-customer-address .option.has-error .select2-container--default .select2-selection--single {
  border: 1px solid red;
}

.create-customer-address .option.has-error span.error,
.edit-customer-address .option.has-error span.error {
  display: inline-block;
  margin-top: 10px;
}

.create-customer-address .option .select2-container--default,
.edit-customer-address .option .select2-container--default {
  margin-bottom: 10px;
}

.create-customer-address .option .select2-container--default .select2-selection--single,
.edit-customer-address .option .select2-container--default .select2-selection--single {
  background: #faf6f4;
  border: none;
  outline: none;
  height: 40px;
  line-height: 40px;
}

.create-customer-address .option .select2-container--default .select2-selection--single .select2-selection__arrow,
.edit-customer-address .option .select2-container--default .select2-selection--single .select2-selection__arrow {
  visibility: hidden;
}

.create-customer-address .option .select2-container--default.select2-container--open .select2-selection--single,
.edit-customer-address .option .select2-container--default.select2-container--open .select2-selection--single {
  border: 1px solid #f0e5dc !important;
}

.customer-profile .hint {
  display: block;
  color: #ff0134;
  margin-bottom: 30px;
  font-weight: bold;
}

.customer-profile .input-group {
  width: 100%;
}

.customer-profile .input-group + .input-group {
  margin-top: 5px;
}

.customer-profile .input-group label {
  font: normal 14px "asap-regular-webfont", sans-serif;
  display: inline-block;
  padding-left: 15px;
  cursor: pointer;
}

.customer-profile .input-group span.helper {
  display: block;
  padding: 5px 0;
}

.customer-profile .input-group-btn {
  width: 1px;
}

.customer-profile .form-group {
  margin: 0 -15px 30px;
}

.customer-profile .checkbox {
  line-height: 50px;
  margin: 0;
}

.customer-profile .form-control {
  border: 0;
  background-color: #f2f2f2;
  border-radius: 5px !important;
  transition: background 0.5s ease;
  float: none;
}

.customer-profile .form-control[readonly] {
  background-color: white;
  cursor: auto;
}

.customer-profile label {
  font: normal 15px "asap-bold-webfont", sans-serif;
  line-height: 34px;
}

.customer-profile .cancel {
  text-decoration: underline;
  color: #ff0134;
}

.customer-profile .profile .edit-show, .customer-profile .profile .update, .customer-profile .profile .cancel {
  display: none;
}

.customer-profile .profile .cancel {
  padding: 0 5px;
}

.customer-profile .profile .edit {
  border-radius: 5px !important;
}

.customer-profile .profile .option {
  height: 40px;
  margin-bottom: 10px;
}

.customer-profile .profile .option .select2-container--default .select2-selection--single {
  background: #f2f2f2;
  border: none;
  outline: none;
  height: 40px;
  line-height: 40px;
}

.customer-profile .profile .option .select2-container--default .select2-selection--single .select2-selection__arrow {
  visibility: hidden;
}

.customer-profile .profile .option .select2-container--open .select2-selection--single {
  border: 1px solid #f0e5dc !important;
}

.customer-profile .profile .col-action .input-div {
  display: none;
}

.customer-profile .profile .col-action .input-div input, .customer-profile .profile .col-action .input-div select, .customer-profile .profile .col-action .input-div textarea {
  margin-bottom: 10px;
}

.customer-profile .profile.editable .edit {
  display: none;
}

.customer-profile .profile.editable .edit-show {
  display: block;
}

.customer-profile .profile.editable .update, .customer-profile .profile.editable .cancel {
  display: inline-block;
}

.customer-profile .profile.editable .input-group {
  display: none;
}

.customer-profile .profile.editable .col-action .input-div {
  display: block;
}

.customer-voucher .voucher {
  margin-bottom: 5px;
}

.customer-voucher .voucher > div {
  height: 100%;
  background-color: white;
  position: relative;
  padding: 15px 15px 15px;
  border-radius: 5px;
}

.customer-voucher .voucher > div.active:before, .customer-voucher .voucher > div.active:after, .customer-voucher .voucher > div:hover:before, .customer-voucher .voucher > div:hover:after {
  opacity: 1;
}

.customer-voucher .voucher .apply {
  width: 200px;
  max-width: 100%;
}

.customer-voucher .voucher .apply.applied {
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.customer-voucher .voucher .apply.applied:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.customer-voucher .voucher h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.customer-voucher .voucher img {
  width: 100%;
  margin-bottom: 10px;
}

.customer-voucher-popup {
  padding: 30px;
  border-radius: 5px;
  position: relative;
  max-width: 420px;
  text-align: center;
}

.customer-voucher-popup .col-action {
  text-align: right;
  margin-top: 15px;
}

.customer-voucher-popup .col-action span {
  padding: 0 15px;
}

.customer-orders .list-panel .panel {
  padding: 15px;
}

.customer-orders .order-list .action {
  text-align: right;
}

@media (max-width: 991px) {
  .customer-orders .order-list .action {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .customer-orders .order-list .action {
    padding: 0 15px;
    text-align: left;
  }
}

.customer-orders .order-list .order .status {
  padding: 10px 15px;
  border-left: 1px solid #f0e5dc;
}

@media (max-width: 767px) {
  .customer-orders .order-list .order .status {
    border-left: 0;
  }
}

.customer-orders .order-list h4 {
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin-bottom: 5px;
}

.customer-orders .order-list h4.green {
  color: #12ca72;
}

.customer-orders .order-list h4.red {
  color: #ff0134;
}

.customer-orders .order-list a {
  color: #f3a200;
  text-decoration: underline;
}

.customer-orders .order-list a.view {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.customer-orders .order-list a.download, .customer-orders .order-list a.deliverer, .customer-orders .order-list a.pay {
  font: normal 13px "asap-bold-webfont", sans-serif;
}

.customer-order-popup {
  padding: 30px;
  border-radius: 5px;
  position: relative;
  max-width: 720px;
}

.customer-order-popup .order-info {
  margin-bottom: 30px;
}

.customer-order-popup .order-info p {
  margin: 0;
}

.customer-order-popup .order-product-list {
  background-color: #fff5eb;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box !important;
  max-height: 300px;
}

@media (max-height: 600px) {
  .customer-order-popup .order-product-list {
    max-height: 200px;
  }
}

.customer-order-popup .order-product-list .list-header {
  margin-top: -5px;
}

.customer-order-popup .img-cont {
  width: 30px;
}

.customer-order-popup .item p {
  line-height: 1;
}

.customer-order-popup .name {
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 1px;
}

.customer-order-popup .desc {
  font: normal 14px "asap-regular-webfont", sans-serif;
  padding-left: 10px;
}

.customer-order-popup .desc:before {
  content: '-';
  margin-left: -10px;
}

.customer-order-popup .amount {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.customer-order-popup .price-strike {
  font: normal 13px "asap-bold-webfont", sans-serif;
  color: #ff0134;
  text-decoration: line-through;
}

.customer-order-popup .unit {
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin-left: 5px;
}

.customer-order-popup .quantity {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.customer-order-popup .price {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.customer-order-popup .tax {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.customer-order-popup .col-action {
  text-align: right;
  margin-top: 15px;
}

.message {
  background-color: #8dbdc5;
  border-radius: 5px;
  padding: 30px 40px;
  position: relative;
}

.message .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  color: white;
  opacity: 0.9;
  font-size: 32px;
  font-weight: 100;
  line-height: 21px;
}

.message h3 {
  color: #ffffff;
  font: normal 18px "asap-bold-webfont", sans-serif;
  margin: 0 0 15px;
}

.message p {
  color: #ffffff;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.order-grid {
  margin: 4px 0 0 2px;
}

.order-grid .grid {
  background-color: white;
  padding: 20px;
}

.order-grid .grid-right {
  padding: 0 4px;
}

.order-grid .grid-right .grid {
  padding: 20px 30px;
}

.order-grid .grid-right .grid + .grid {
  margin-top: 4px;
}

.order-grid h1 {
  color: #62534f;
  font: normal 30px "asap-regular-webfont", sans-serif;
  margin: 0 0 20px;
  line-height: 1;
}

.order-grid h4 {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  font-size: 17px;
  margin: 0;
}

.list-panel .list-header {
  color: #887975;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.list-panel .list-header > div {
  padding: 10px 15px;
}

.list-panel .panel {
  border-radius: 5px;
  padding: 15px 0;
  margin: 0;
}

.list-panel .panel + .panel {
  margin-top: 4px;
}

.list-panel p {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin: 0;
  line-height: 1.5;
}

.customer-dashboard .order-list .order {
  background-color: #fdf1d9;
}

.customer-dashboard .order-list .order + .order {
  margin-top: 4px;
}

.customer-dashboard .order-list .order-no a {
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: underline;
}

.quick-links .link {
  padding: 30px 0 30px 60px;
  position: relative;
}

.quick-links .link:before {
  content: '';
  background-image: url("../../img/uno-shoppe-v3/icon_apple_default.png");
  position: absolute;
  top: 30px;
  left: 15px;
  width: 20px;
  height: 20px;
}

.quick-links .link + .link {
  border-top: 1px solid #f3a200;
}

.quick-links h3 {
  color: #62534f;
  font: normal 18px "asap-bold-webfont", sans-serif;
  margin: 0 0 5px;
}

.quick-links p {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin: 0;
}

.balance {
  border-radius: 5px;
  background-color: white;
  padding: 30px;
  display: inline-block;
  margin: 0 0 50px;
}

.balance h3 {
  color: #62534f;
  font: normal 18px "asap-bold-webfont", sans-serif;
  margin: 0 0 5px;
}

.balance .amount span {
  color: #62534f;
  font: normal 30px "asap-regular-webfont", sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.balance .amount .btn, .balance .amount .product .col-btn button, .product .col-btn .balance .amount button, .balance .amount .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .balance .amount button, .balance .amount .suggestion-popup .col-btn button, .suggestion-popup .col-btn .balance .amount button {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
}

@media (max-width: 480px) {
  .balance .amount .btn, .balance .amount .product .col-btn button, .product .col-btn .balance .amount button, .balance .amount .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .balance .amount button, .balance .amount .suggestion-popup .col-btn button, .suggestion-popup .col-btn .balance .amount button {
    margin-left: 0;
  }
}

.transaction-list .debit, .transaction-list .credit {
  text-align: right;
}

.transaction-list .transaction {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.transaction-list .transaction .debit, .transaction-list .transaction .credit {
  text-align: right;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

@media (max-width: 767px) {
  .transaction-list .transaction .debit, .transaction-list .transaction .credit {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .transaction-list .transaction .debit[data-amonunt]:before {
    content: 'Debit: ';
    display: inline-block;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  .transaction-list .transaction .credit[data-amonunt]:before {
    content: 'Credit: ';
    display: inline-block;
    margin-right: 5px;
  }
}

.transaction-list a {
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: underline;
}

.review-add-popup {
  padding: 30px;
  border-radius: 5px;
  position: relative;
  max-width: 520px;
}

.review-add-popup .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  color: white;
  opacity: 0.9;
  font-size: 32px;
  font-weight: 100;
  line-height: 21px;
}

.review-add-popup h1 {
  font: normal 24px "asap-bold-webfont", sans-serif;
  margin: 0 0 15px;
}

.review-add-popup h4 {
  margin: 0 0 5px;
}

.review-add-popup p {
  font: normal 14px "asap-regular-webfont", sans-serif;
  margin: 0;
}

.review-add-popup .col-action {
  text-align: right;
  margin-top: 15px;
}

.review-add-popup .review-rating {
  padding: 30px 0;
}

.review-add-popup textarea {
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 5px;
  min-height: 150px;
  border: 0;
}

.review-stars .star {
  -webkit-text-stroke: initial;
  text-shadow: initial;
}

.review-stars .star .fa-star {
  font-size: 24px;
  color: #f0e5dc;
  transition: all 0.5s ease;
}

.review-stars .star .fa-star:hover {
  color: #f3a200;
}

.review-stars .filled-stars .fa-star {
  color: #f3a200;
}

.rating-option[data-rating="1"] .fa-star:first-child {
  color: #f3a200;
}

.rating-option[data-rating="2"] .fa-star:first-child, .rating-option[data-rating="2"] .fa-star:nth-child(2) {
  color: #f3a200;
}

.rating-option[data-rating="3"] .fa-star:first-child, .rating-option[data-rating="3"] .fa-star:nth-child(2), .rating-option[data-rating="3"] .fa-star:nth-child(3) {
  color: #f3a200;
}

.rating-option[data-rating="4"] .fa-star:first-child, .rating-option[data-rating="4"] .fa-star:nth-child(2), .rating-option[data-rating="4"] .fa-star:nth-child(3), .rating-option[data-rating="4"] .fa-star:nth-child(4) {
  color: #f3a200;
}

.rating-option[data-rating="5"] .fa-star:first-child, .rating-option[data-rating="5"] .fa-star:nth-child(2), .rating-option[data-rating="5"] .fa-star:nth-child(3), .rating-option[data-rating="5"] .fa-star:nth-child(4), .rating-option[data-rating="5"] .fa-star:nth-child(5) {
  color: #f3a200;
}

.rating-option .fa-star {
  font-size: 18px;
  color: #f0e5dc;
  transition: all 0.5s ease;
}

.rating-option .fa-star:hover {
  color: #f3a200;
}

.suggestion-popup {
  border-radius: 5px;
  position: relative;
  max-width: 960px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .suggestion-popup {
    max-width: 560px;
  }
}

.suggestion-popup h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.suggestion-popup .left-content {
  padding: 30px;
}

.suggestion-popup .right-content {
  border-left: 1px solid #f0e5dc;
  padding: 30px;
}

@media (max-width: 991px) {
  .suggestion-popup .right-content {
    border: 0;
    border-bottom: 1px solid #f0e5dc;
    margin: 0;
  }
}

.suggestion-popup .img-cont {
  position: relative;
  background-size: contain;
}

.suggestion-popup .img-cont:before {
  padding-top: 100%;
}

.suggestion-popup p {
  margin: 0;
  line-height: 1.4;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.suggestion-popup .name {
  margin-bottom: 30px;
  font-size: 16px;
}

.suggestion-popup .price {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.suggestion-popup .unit {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.suggestion-popup .price-strike {
  color: #ff0134;
  font: normal 13px "asap-bold-webfont", sans-serif;
}

.suggestion-popup .price-strike s {
  text-decoration: line-through;
}

.suggestion-popup .option {
  max-width: 240px;
}

.suggestion-popup .quantity-control {
  width: 100%;
  min-width: 110px;
  max-width: 240px;
}

.suggestion-popup .points {
  font: normal 15px "asap-bold-webfont", sans-serif;
  color: #f3a200;
  margin-top: 10px;
}

.suggestion-popup .rating {
  color: #ffb700;
  line-height: 2;
}

.suggestion-popup .col-content {
  padding: 30px 0;
}

.suggestion-popup .col-action > div {
  padding-top: 4px;
}

.suggestion-popup .col-btn {
  padding-top: 4px;
  max-width: 300px;
}

.suggestion-popup .col-btn button {
  width: 100%;
  height: 35px;
  padding: 0;
  border: 0;
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  line-height: 35px;
}

.suggestion-popup .col-btn button.btn-fav {
  background-color: #ff0134;
  position: relative;
}

.suggestion-popup .col-btn button.btn-fav:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: 0;
  transition: opacity .3s;
}

.suggestion-popup .col-btn button.btn-fav:hover:after, .suggestion-popup .col-btn button.btn-fav.favourited:after {
  opacity: 0.5;
}

.suggestion-popup .col-btn button.btn-fav img {
  margin: auto;
}

.suggestion-popup .col-btn button.btn-buy, .suggestion-popup .col-btn button.btn-buy-instead {
  background-color: #f3a200;
}

.suggestion-popup .col-btn button.btn-buy:after, .suggestion-popup .col-btn button.btn-buy-instead:after {
  content: '\f07a';
  font-family: FontAwesome;
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
  line-height: 1;
}

.suggestion-popup .col-btn button.btn-bargain {
  background-color: #f3a200;
}

.suggestion-popup .col-btn button.btn-cancel {
  background-color: #ff0134;
}

.suggestion-popup .col-btn button.btn-cancel:after {
  content: '\f00d';
  font-family: FontAwesome;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.suggestion-popup .section-adding-to-cart {
  text-align: center;
}

@media (max-width: 991px) {
  .suggestion-popup .section-adding-to-cart {
    text-align: left;
  }
}

.suggestion-popup .section-adding-to-cart .col-content {
  text-align: left;
  padding: 15px 30px;
  max-width: 300px;
}

.suggestion-popup .section-adding-to-cart .name {
  margin: 0;
}

.suggestion-popup .section-adding-to-cart img {
  max-width: 220px;
  max-height: 220px;
  display: block;
}

.suggestion-popup .section-adding-to-cart .points {
  text-align: center;
}

.suggestion-popup .section-adding-to-cart .col-btn {
  padding-top: 15px;
}

.select2.select2-container {
  width: 100% !important;
}

.select2-container {
  width: initial !important;
}

.select2-container .select2-results h4 {
  margin: 0;
}

.unfav {
  background-color: #f3a200 !important;
  background-image: url("../../img/uno-shoppe-v3/icon_favourite_remove.png") !important;
}

/*#home-content {
  .home-content-block {
    position: relative;
    .home-content-block-text {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      background-color: rgba(255,255,255,0.6);
      .home-content-block-text-header {
        margin-bottom: 10px;
        color: $color_3;
        font: $font_2;
        font-weight: bold;
        text-transform: uppercase;
        display: table;
        width: 100%;
        > div {
          display: table-cell;
          vertical-align: middle;
        }
      }
      .home-content-block-text-body {
        color: $color_2;
        text-transform: uppercase;
        font: $font_1;
        font-weight: bold;
      }
    }
  }
}*/
.home {
  padding: 30px 0;
}

.home > .container > div + div {
  margin-top: 50px;
}

.home-content .img-cont:before {
  content: '';
  padding-top: 56%;
}

.catalog-section {
  padding: 0;
  margin: 30px 0;
}

.catalog-list {
  margin: 30px 0;
}

.catalog-list .cat-title {
  color: #ffb700;
  font: normal 12px "asap-regular-webfont", sans-serif;
  text-transform: uppercase;
  line-height: 1.5;
}

.catalog-list .cat-title span {
  padding: 15px;
  display: block;
}

.catalog-list .product-list {
  list-style: none;
  padding: 0 5px;
  margin: 0;
}

.catalog-list .product-list li {
  padding: 0 0.5px;
}

.catalog-list .product-list .img {
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 120%;
  position: relative;
}

.catalog-list .product-list .hover-links {
  background: rgba(98, 83, 79, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 60%;
  text-align: center;
  transition: all 0.3s ease;
  opacity: 0;
}

.catalog-list .product-list .hover-links:hover {
  opacity: 1;
}

.catalog-list .product-list .hover-links a {
  display: inline-block;
  border: 5px solid #ffb700;
  border-radius: 50%;
  color: #ffb700;
  width: 35px;
  height: 35px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin: 0 5px;
  text-align: center;
  transition: all 0.5s ease;
}

.catalog-list .product-list .hover-links a:before {
  content: '';
  font-family: FontAwesome;
  font-size: 16px;
  line-height: 25px;
  transition: all 0.5s ease;
}

.catalog-list .product-list .hover-links a:hover {
  border-color: #ffffff;
}

.catalog-list .product-list .hover-links a:hover:before {
  color: #ffffff;
}

.catalog-list .product-list .hover-links .fav-btn:before {
  content: '\f004';
}

.catalog-list .product-list .hover-links .details-btn:before {
  content: '\f105';
  font-size: 18px;
}

.catalog-list .product-list .hover-links .cart-btn:before {
  content: '\f07a';
}

.catalog-list .product-list .hover-links .btnTxt {
  display: block;
  margin-top: 5px;
  color: #ffb700;
  font: normal 14px "asap-regular-webfont", sans-serif;
  text-transform: uppercase;
}

.catalog-list .product-list .details {
  position: relative;
  text-align: center;
}

.catalog-list .product-list h3.title {
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-transform: uppercase;
  padding: 30px 0;
  margin: 0;
}

.catalog-list .product-list .label {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  background: #ffb700;
  border-radius: 0;
  color: #62534f;
  font: normal 11px "asap-bold-webfont", sans-serif;
  text-transform: uppercase;
}

.catalog-list .product-list .price-cont {
  font: normal 11px "asap-bold-webfont", sans-serif;
}

.catalog-list .product-list .price-cont .price {
  color: #faf6f4;
  padding: 0 5px;
}

.catalog-list .product-list .price-cont .strikeprice {
  color: #fac1b3;
  text-decoration: line-through;
  padding: 0 5px;
}

#home-video-cont {
  /*padding: 0;
  height: 279px;
  a {
    display: block;
    width: 100%;
  }
  .vid {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }*/
}

#home-customer-value {
  /*border: { top: 15px solid rgba(white, 0.1); bottom: 15px solid rgba(black, 0.06); };
  padding: 20px 0; background-color: $lightblue;
  @include transition(all 0.5s ease-in-out);
  .title { color: $darkblue; }
  img { margin-bottom: 15px; }
  p { color: white; }*/
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

.home-content {
  text-align: center;
  background-color: #ffffff;
}

.home-content .main-image {
  margin-bottom: 5px;
}

.home-content .main-image img {
  margin: auto;
}

header {
  position: relative;
  z-index: 11;
}

.header-top {
  background-color: #62534f;
  color: #ffffff;
  padding: 5px 0;
  position: relative;
  transition: all 0.5s ease;
  transition-delay: 0.2s;
  top: 0;
  opacity: 1;
}

.sticky .header-top {
  top: -25px;
  opacity: 0;
  transition: all 0s ease;
  transition-delay: 0s;
}

.header-top.journal-header {
  padding: 30px 0;
  top: 0;
  opacity: 1;
}

.header-top .header-left {
  text-align: left;
  font: normal 13px "asap-bold-webfont", sans-serif;
  font-style: italic;
}

@media (max-width: 767px) {
  .header-top .header-left {
    text-align: center;
  }
}

.header-top .header-left .back-btn {
  font: normal 15px "asap-bold-webfont", sans-serif;
  color: white;
}

.header-top .header-right {
  text-align: right;
}

@media (max-width: 767px) {
  .header-top .header-right {
    text-align: center;
  }
}

@-webkit-keyframes stickyHeader-init {
  0% {
    top: -30px;
  }
  100% {
    top: 0;
  }
}

@keyframes stickyHeader-init {
  0% {
    top: -30px;
  }
  100% {
    top: 0;
  }
}

.header-main-offset {
  /*@include transition(all 0.5s ease);*/
  height: 0;
}

.header-main {
  position: relative;
  transition: all 0.5s ease;
  background: white;
  z-index: 2;
}

@media (max-width: 991px) {
  .header-main > .container {
    width: 100%;
    padding: 0;
  }
}

.group-type-list .nav-tabs li a {
  padding: 5px 15px;
  font-size: 14px;
}

.group-type-list .tab-content .group > a {
  font-size: 14px;
}

.sticky .group-type-list {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  -webkit-animation: stickyHeader-init ease 0.4s;
  animation: stickyHeader-init ease 0.4s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.header-links li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}

.header-links li:last-child {
  padding-right: 0;
}

.header-links a {
  color: #ffffff;
  font: normal 13px "asap-bold-webfont", sans-serif;
  padding: 5px 0;
  letter-spacing: 0.5px;
}

.header-links a.btn-journal {
  padding: 0;
}

.header-bottom {
  padding: 0;
}

.widget-bar, .header-logo, .search-bar {
  padding: 15px 0;
}

.widget-bar {
  text-align: right;
}

@media (max-width: 991px) {
  .widget-bar {
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: #f3a200;
  }
}

@media (max-width: 991px) {
  .header-logo {
    text-align: center;
    padding: 10px 0;
  }
}

@media (max-width: 991px) {
  .header-logo a {
    line-height: 50px;
  }
}

.header-logo .header-logo-category, .header-logo .header-logo-menu {
  width: 48px;
  height: 50px;
  padding: 0 10px;
  cursor: pointer;
}

.header-logo .header-logo-category {
  float: left;
}

.header-logo .header-logo-menu {
  float: right;
}

@media (max-width: 991px) {
  .header-logo #logo {
    max-width: 120px;
  }
}

@media (max-width: 991px) {
  .search-bar {
    padding: 15px;
    background-color: #f0e5dc;
  }
}

.header-widget-mobile {
  color: #ffffff;
  width: 50%;
  text-align: center;
}

@media (max-width: 991px) {
  .header-widget-mobile {
    display: table-cell !important;
    vertical-align: middle;
    background-color: #62534f;
  }
}

.header-widget-mobile a {
  font-size: 24px;
  width: 50%;
  float: left;
  display: block;
  padding: 10px;
  border-right: 1px solid #ffffff;
}

.header-widget-mobile a:hover {
  color: #ffffff;
}

.header-user {
  display: inline-block;
  vertical-align: middle;
  padding: 0 15px;
  color: #62534f;
  position: relative;
  line-height: 40px;
}

.header-user.active:after {
  content: '\f0d7';
  font-family: FontAwesome;
  font-size: 14px;
  margin-left: 5px;
}

.header-user .link {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .header-user .link {
    color: #ffffff;
  }
}

.header-user span {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.header-user .name {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.header-user:hover .dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: all 0.5s ease;
  top: 35px;
}

.header-user .dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  background-color: white;
  border-radius: 5px 0 5px 5px;
  z-index: 1;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  text-align: left;
}

.header-user .dropdown ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.header-user .dropdown a {
  display: block;
  padding: 15px;
  transition: all 0.5s ease;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.header-user .dropdown a:hover {
  background-color: #eadcd3;
}

.header-cart-bar {
  display: inline-block;
  vertical-align: middle;
  background-color: #f3a200;
  border-radius: 5px;
  color: #ffffff;
  padding: 0 15px;
  line-height: 38px;
  height: 38px;
}

@media (max-width: 991px) {
  .header-cart-bar {
    width: 50%;
    border-radius: 0;
    text-align: center;
    display: table-cell;
  }
}

.header-cart-bar:after {
  content: '\f0d7';
  font-family: FontAwesome;
  font-size: 14px;
  margin-left: 5px;
}

@media (max-width: 991px) {
  .header-cart-bar:after {
    content: none;
  }
}

.header-cart-bar .cart {
  color: #ffffff;
}

@media (max-width: 991px) {
  .header-cart-bar .cart {
    white-space: nowrap;
  }
}

.header-cart-bar .cart > .fa-shopping-cart, .header-cart-bar .cart span {
  vertical-align: middle;
}

.header-cart-bar .cart > .fa-shopping-cart {
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .header-cart-bar .cart > .fa-shopping-cart {
    font-size: 24px;
    line-height: 38px;
    margin-right: 10px;
  }
}

.header-cart-bar span {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.header-cart-bar .number {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.header-cart-bar .amount {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.header-cart-bar:hover .dropdown, .header-cart-bar.hover .dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: all 0.5s ease;
  top: 35px;
}

.header-cart-bar .dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  width: 360px;
  min-height: 100px;
  background-color: white;
  border-radius: 5px 0 5px 5px;
  z-index: 1;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
}

@media (max-width: 991px) {
  .header-cart-bar .dropdown {
    top: 100% !important;
  }
}

@media (max-width: 480px) {
  .header-cart-bar .dropdown {
    width: 100%;
  }
}

.header-cart-bar .dropdown .header-cart-list {
  max-height: 220px;
}

.header-cart-bar .dropdown .header-cart-list .header-cart-product {
  color: #62534f;
  font: normal 12px "asap-regular-webfont", sans-serif;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  transition: background-color 1s;
}

.header-cart-bar .dropdown .header-cart-list .header-cart-product + .header-cart-product {
  border-top: 1px solid #f0e5dc;
}

.header-cart-bar .dropdown .header-cart-list .header-cart-product.temp-active {
  -webkit-animation-name: change_background;
  /* Chrome, Safari, Opera */
  -webkit-animation-duration: 6s;
  /* Chrome, Safari, Opera */
  animation-name: change_background;
  animation-duration: 6s;
}

.header-cart-bar .dropdown .header-cart-list .img-cont {
  padding: 0;
}

.header-cart-bar .dropdown .header-cart-list .img-cont:before {
  padding-top: 100%;
}

.header-cart-bar .dropdown .header-cart-list .name {
  padding: 0 5px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: table-cell;
}

.header-cart-bar .dropdown .header-cart-list .quantity {
  padding: 0 5px;
}

.header-cart-bar .dropdown .header-cart-list .price {
  padding: 0;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.header-cart-bar .dropdown .header-cart-list .action {
  padding: 0 10px;
}

.header-cart-bar .dropdown .header-cart-list .action .close {
  float: none;
  font-size: 16px;
}

.header-cart-bar .dropdown .header-cart-list .action .remove {
  cursor: pointer;
}

.header-cart-bar .dropdown .sub-total {
  text-align: right;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
  padding: 20px 0;
}

.header-cart-bar .dropdown .amount {
  color: #ff0134;
  padding: 0 15px;
}

.header-cart-bar .col-action {
  padding: 0 20px 20px;
  border-top: 1px solid #f0e5dc;
}

.header-cart-bar .col-action .btn .fa, .header-cart-bar .col-action .product .col-btn button .fa, .product .col-btn .header-cart-bar .col-action button .fa, .header-cart-bar .col-action .product-detail .product-info .col-btn button .fa, .product-detail .product-info .col-btn .header-cart-bar .col-action button .fa, .header-cart-bar .col-action .suggestion-popup .col-btn button .fa, .suggestion-popup .col-btn .header-cart-bar .col-action button .fa {
  margin-left: 5px;
}

.sticky-header-top {
  display: inline-block;
  vertical-align: middle;
  background-color: #62534f;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  position: relative;
  visibility: hidden;
  opacity: 0;
  width: 0;
  line-height: 1;
  height: 38px;
  transition: all 0.5s ease;
}

@media (max-width: 991px) {
  .sticky-header-top {
    display: none;
  }
}

.sticky-header-top a {
  color: inherit;
  line-height: 1;
}

.sticky-header-top .fa {
  font-size: 18px;
}

.sticky .sticky-header-top {
  visibility: visible;
  opacity: 1;
  margin-left: 5px;
  width: 48px;
  padding: 10px 15px;
}

.sticky-header-top:hover .dropdown {
  top: 35px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.sticky-header-top .dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  min-width: 140px;
  background-color: #62534f;
  transition: all 0.5s ease;
  border-radius: 5px 0 5px 5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.sticky-header-top ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sticky-header-top ul a {
  padding: 10px 15px;
  color: #ffffff;
  font: normal 15px "asap-bold-webfont", sans-serif;
  letter-spacing: 0.5px;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes change_background {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #FFD88A;
  }
  100% {
    background-color: transparent;
  }
}

/* Standard syntax */
@keyframes change_background {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #FFD88A;
  }
  100% {
    background-color: transparent;
  }
}

.search-bar {
  position: relative;
}

.search-bar input[name=keyword] {
  border-radius: 15px;
  background: #FFF5EB;
  border: 0;
  margin-bottom: 0;
  width: 100%;
  display: block;
  padding: 10px 40px 10px 20px;
  height: 40px;
  position: inherit;
  z-index: 11;
}

.search-bar button[type=submit] {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 10px;
  margin: auto;
  box-shadow: none;
  background: none;
  border: none;
  z-index: 12;
}

.search-bar button[type=submit] i.fa {
  font-size: 14px;
}

.search-bar form {
  margin: 0;
  position: relative;
}

.search-dropdown {
  margin-top: -15px;
  position: absolute;
  width: 100%;
  padding-top: 15px;
  border-radius: 0 0 15px 15px;
  background: #FFF5EB;
  z-index: 10;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.3);
}

.search-dropdown-ul {
  list-style: none;
  padding: 0;
}

.search-dropdown-item {
  padding: 0 20px;
  line-height: 30px;
}

.search-dropdown-item a {
  display: block;
}

.search-dropdown-item a:hover {
  color: #f3a200;
}

.search-dropdown-item:hover {
  background: white;
}

.sticky .group-type-list {
  z-index: 15;
}

footer .btn-newsletter {
  color: white;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 5px;
}

#footer-content {
  background-color: #62534f;
  padding: 10px 0;
}

#footer-content p {
  color: #eadcd3;
  font: normal 12px "asap-regular-webfont", sans-serif;
  line-height: 1.4;
}

#footer-content .copyright p {
  margin: 0;
}

#footer-content .copyright a {
  color: #ffffff;
  position: relative;
}

#footer-content .copyright a + a {
  padding-left: 20px;
}

#footer-content .copyright a + a:before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 8px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

#footer-content .bottom-footer {
  padding-top: 15px;
}

#footer-content .bottom-footer .links {
  color: white;
}

#footer-content .bottom-footer .site-map {
  color: white;
}

#footer-content .footer-logo + .quick-nav {
  margin-top: 30px;
}

#footer-content .quick-nav + .category-nav {
  margin-top: 30px;
}

#footer-content .quick-nav ul {
  list-style: none;
  padding: 0;
  margin: -5px 0 0;
}

#footer-content .quick-nav a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  padding: 2px 0;
  color: #eadcd3;
  font: normal 15px "asap-bold-webfont", sans-serif;
  font-size: 13px !important;
}

#footer-content .category-nav + .category-nav {
  margin-top: 30px;
}

#footer-content .category-nav h4 {
  margin: 0;
}

#footer-content .category-nav h4 a {
  display: inline-block;
  padding: 2px 0;
  color: #eadcd3;
  font: normal 15px "asap-bold-webfont", sans-serif;
  font-size: 13px !important;
}

#footer-content .category-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer-content .category-nav a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  padding: 2px 0;
  color: #eadcd3;
  font: normal 14px "asap-regular-webfont", sans-serif;
  font-size: 12px;
}

* {
  box-sizing: border-box !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline-block-mid {
  display: inline-block;
  vertical-align: middle;
}

.inline {
  display: inline;
}

.transition {
  transition: all 0.5s ease;
}

.col-half, .col-half-padding {
  padding: 0 7.5px;
}

.col-mini, .col-mini-padding {
  padding: 0 5px;
}

.col-tiny, .col-tiny-padding {
  padding: 0 2px;
}

.col-double {
  padding-left: 30px;
  padding-right: 30px;
}

.col-sm-tiny {
  padding-left: 2px;
  padding-right: 2px;
}

@media (max-width: 767px) {
  .col-sm-tiny {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-md-tiny {
  padding-left: 2px;
  padding-right: 2px;
}

@media (max-width: 991px) {
  .col-md-tiny {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-sm-35px {
  width: 35px;
}

@media (max-width: 480px) {
  .col-sm-35px {
    width: auto;
  }
}

.col-sm-100px {
  width: 100px;
}

@media (max-width: 480px) {
  .col-sm-100px {
    width: auto;
  }
}

.col-sm-120px {
  width: 120px;
}

@media (max-width: 480px) {
  .col-sm-120px {
    width: auto;
  }
}

.col-sm-140px {
  width: 140px;
}

@media (max-width: 480px) {
  .col-sm-140px {
    width: auto;
  }
}

.padding-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.col-10-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.row-30, .row-double {
  margin-left: -30px;
  margin-right: -30px;
}

.row-sm {
  margin-right: -15px;
  margin-left: -15px;
}

@media (max-width: 495px) {
  .row-sm {
    margin-right: 0;
    margin-left: 0;
  }
}

.row-md {
  margin-right: -15px;
  margin-left: -15px;
}

@media (max-width: 1006px) {
  .row-md {
    margin-right: 0;
    margin-left: 0;
  }
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.row-mini {
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.row-tiny {
  margin-right: -2px;
  margin-left: -2px;
}

.row-sm-min {
  margin-right: -7.5px;
  margin-left: -7.5px;
}

@media (max-width: 480px) {
  .row-sm-min {
    margin-right: 0;
    margin-left: 0;
  }
}

.row-md-tiny {
  margin-right: -2px;
  margin-left: -2px;
}

@media (max-width: 991px) {
  .row-md-tiny {
    margin-right: 0;
    margin-left: 0;
  }
}

.row-sm-tiny {
  margin-right: -2px;
  margin-left: -2px;
}

@media (max-width: 480px) {
  .row-sm-tiny {
    margin-right: 0;
    margin-left: 0;
  }
}

.width-full {
  width: 100% !important;
}

.width-half {
  width: 50% !important;
}

.width-auto {
  width: auto !important;
}

.height-full {
  height: 100%;
}

.col-grid {
  position: relative;
}

.col-grid.three-quarter, .col-grid.square, .col-grid.half, .col-grid.sm-three-quarter {
  overflow: hidden;
}

.col-grid.three-quarter .grid-content, .col-grid.square .grid-content, .col-grid.half .grid-content, .col-grid.sm-three-quarter .grid-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 30px;
}

.col-grid.three-quarter:before {
  content: '';
  display: block;
  padding-top: 75%;
}

.col-grid.half:before {
  content: '';
  display: block;
  padding-top: 50%;
}

.col-grid.square:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.col-grid.sm-three-quarter:before {
  content: '';
}

@media (min-width: 768px) {
  .col-grid.sm-three-quarter:before {
    display: block;
    padding-top: 75%;
  }
}

@media (min-width: 768px) {
  .col-grid.sm-three-quarter {
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .col-grid.sm-three-quarter .grid-content {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
  }
}

.col-grid .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.col-grid .bg.white {
  background-color: white;
}

.col-xs-35px {
  width: 35px;
}

.col-xs-42px {
  width: 42px;
}

.p-5 {
  padding: 5px;
}

.img-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.img-cont {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.product.grid {
  min-height: 250px;
}

.customer-signup, .form-signup, .cart-add-address,
.cart-edit-address, .cart-signup, .guest-signup, .cart-address, .cart-delivery, .payment-method-choice, .create-customer-address,
.edit-customer-address, .contact-us {
  /*inner content shared style, apply to some pages eg: contact-us page, signup page, */
}

.customer-signup h3, .form-signup h3, .cart-add-address h3,
.cart-edit-address h3, .cart-signup h3, .guest-signup h3, .cart-address h3, .cart-delivery h3, .payment-method-choice h3, .create-customer-address h3,
.edit-customer-address h3, .contact-us h3 {
  margin-bottom: 15px;
}

.customer-signup p, .form-signup p, .cart-add-address p,
.cart-edit-address p, .cart-signup p, .guest-signup p, .cart-address p, .cart-delivery p, .payment-method-choice p, .create-customer-address p,
.edit-customer-address p, .contact-us p {
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.customer-signup p.hint, .form-signup p.hint, .cart-add-address p.hint,
.cart-edit-address p.hint, .cart-signup p.hint, .guest-signup p.hint, .cart-address p.hint, .cart-delivery p.hint, .payment-method-choice p.hint, .create-customer-address p.hint,
.edit-customer-address p.hint, .contact-us p.hint {
  color: #ff0134;
}

.customer-signup .content, .form-signup .content, .cart-add-address .content,
.cart-edit-address .content, .cart-signup .content, .guest-signup .content, .cart-address .content, .cart-delivery .content, .payment-method-choice .content, .create-customer-address .content,
.edit-customer-address .content, .contact-us .content {
  height: 100%;
  background-color: white;
  padding: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .customer-signup .content, .form-signup .content, .cart-add-address .content,
  .cart-edit-address .content, .cart-signup .content, .guest-signup .content, .cart-address .content, .cart-delivery .content, .payment-method-choice .content, .create-customer-address .content,
  .edit-customer-address .content, .contact-us .content {
    padding: 15px;
  }
}

.full.customer-signup .form-control, .full.form-signup .form-control, .full.cart-add-address .form-control,
.full.cart-edit-address .form-control, .full.cart-signup .form-control, .full.guest-signup .form-control, .full.cart-address .form-control, .full.cart-delivery .form-control, .full.payment-method-choice .form-control, .full.create-customer-address .form-control,
.full.edit-customer-address .form-control, .full.contact-us .form-control, .customer-signup .full .form-control, .form-signup .full .form-control, .cart-add-address .full .form-control,
.cart-edit-address .full .form-control, .cart-signup .full .form-control, .guest-signup .full .form-control, .cart-address .full .form-control, .cart-delivery .full .form-control, .payment-method-choice .full .form-control, .create-customer-address .full .form-control,
.edit-customer-address .full .form-control, .contact-us .full .form-control {
  max-width: 100%;
}

.customer-signup form, .form-signup form, .cart-add-address form,
.cart-edit-address form, .cart-signup form, .guest-signup form, .cart-address form, .cart-delivery form, .payment-method-choice form, .create-customer-address form,
.edit-customer-address form, .contact-us form {
  margin: 0;
}

.customer-signup label, .form-signup label, .cart-add-address label,
.cart-edit-address label, .cart-signup label, .guest-signup label, .cart-address label, .cart-delivery label, .payment-method-choice label, .create-customer-address label,
.edit-customer-address label, .contact-us label {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: inline-block;
  padding-left: 0;
}

.customer-signup label sup, .form-signup label sup, .cart-add-address label sup,
.cart-edit-address label sup, .cart-signup label sup, .guest-signup label sup, .cart-address label sup, .cart-delivery label sup, .payment-method-choice label sup, .create-customer-address label sup,
.edit-customer-address label sup, .contact-us label sup {
  color: #ff0134;
  font-size: 18px;
  vertical-align: text-bottom;
}

.customer-signup .form-control, .form-signup .form-control, .cart-add-address .form-control,
.cart-edit-address .form-control, .cart-signup .form-control, .guest-signup .form-control, .cart-address .form-control, .cart-delivery .form-control, .payment-method-choice .form-control, .create-customer-address .form-control,
.edit-customer-address .form-control, .contact-us .form-control {
  background-color: #faf6f4;
  border: 0;
  border-radius: 5px;
  max-width: 300px;
  margin-bottom: 10px;
}

.customer-signup .form-control + .form-control, .form-signup .form-control + .form-control, .cart-add-address .form-control + .form-control,
.cart-edit-address .form-control + .form-control, .cart-signup .form-control + .form-control, .guest-signup .form-control + .form-control, .cart-address .form-control + .form-control, .cart-delivery .form-control + .form-control, .payment-method-choice .form-control + .form-control, .create-customer-address .form-control + .form-control,
.edit-customer-address .form-control + .form-control, .contact-us .form-control + .form-control {
  margin-top: 5px;
}

.customer-signup .form-group.from-group-multi-column.has-error, .form-signup .form-group.from-group-multi-column.has-error, .cart-add-address .form-group.from-group-multi-column.has-error,
.cart-edit-address .form-group.from-group-multi-column.has-error, .cart-signup .form-group.from-group-multi-column.has-error, .guest-signup .form-group.from-group-multi-column.has-error, .cart-address .form-group.from-group-multi-column.has-error, .cart-delivery .form-group.from-group-multi-column.has-error, .payment-method-choice .form-group.from-group-multi-column.has-error, .create-customer-address .form-group.from-group-multi-column.has-error,
.edit-customer-address .form-group.from-group-multi-column.has-error, .contact-us .form-group.from-group-multi-column.has-error {
  padding-bottom: 30px;
}

.customer-signup .form-group.from-group-multi-column span.error, .form-signup .form-group.from-group-multi-column span.error, .cart-add-address .form-group.from-group-multi-column span.error,
.cart-edit-address .form-group.from-group-multi-column span.error, .cart-signup .form-group.from-group-multi-column span.error, .guest-signup .form-group.from-group-multi-column span.error, .cart-address .form-group.from-group-multi-column span.error, .cart-delivery .form-group.from-group-multi-column span.error, .payment-method-choice .form-group.from-group-multi-column span.error, .create-customer-address .form-group.from-group-multi-column span.error,
.edit-customer-address .form-group.from-group-multi-column span.error, .contact-us .form-group.from-group-multi-column span.error {
  position: absolute;
  top: 100%;
}

.customer-signup .from-group-multi-column .option, .form-signup .from-group-multi-column .option, .cart-add-address .from-group-multi-column .option,
.cart-edit-address .from-group-multi-column .option, .cart-signup .from-group-multi-column .option, .guest-signup .from-group-multi-column .option, .cart-address .from-group-multi-column .option, .cart-delivery .from-group-multi-column .option, .payment-method-choice .from-group-multi-column .option, .create-customer-address .from-group-multi-column .option,
.edit-customer-address .from-group-multi-column .option, .contact-us .from-group-multi-column .option {
  padding-bottom: 10px;
}

.customer-signup .from-group-multi-column .option .form-control, .form-signup .from-group-multi-column .option .form-control, .cart-add-address .from-group-multi-column .option .form-control,
.cart-edit-address .from-group-multi-column .option .form-control, .cart-signup .from-group-multi-column .option .form-control, .guest-signup .from-group-multi-column .option .form-control, .cart-address .from-group-multi-column .option .form-control, .cart-delivery .from-group-multi-column .option .form-control, .payment-method-choice .from-group-multi-column .option .form-control, .create-customer-address .from-group-multi-column .option .form-control,
.edit-customer-address .from-group-multi-column .option .form-control, .contact-us .from-group-multi-column .option .form-control {
  margin-bottom: 0;
}

.customer-signup .option, .form-signup .option, .cart-add-address .option,
.cart-edit-address .option, .cart-signup .option, .guest-signup .option, .cart-address .option, .cart-delivery .option, .payment-method-choice .option, .create-customer-address .option,
.edit-customer-address .option, .contact-us .option {
  max-width: 300px;
}

.customer-signup .option select, .form-signup .option select, .cart-add-address .option select,
.cart-edit-address .option select, .cart-signup .option select, .guest-signup .option select, .cart-address .option select, .cart-delivery .option select, .payment-method-choice .option select, .create-customer-address .option select,
.edit-customer-address .option select, .contact-us .option select {
  border: 0;
  height: 40px;
}

.customer-signup .option select.error, .form-signup .option select.error, .cart-add-address .option select.error,
.cart-edit-address .option select.error, .cart-signup .option select.error, .guest-signup .option select.error, .cart-address .option select.error, .cart-delivery .option select.error, .payment-method-choice .option select.error, .create-customer-address .option select.error,
.edit-customer-address .option select.error, .contact-us .option select.error {
  border: 1px solid #ff4136;
}

.customer-signup select, .form-signup select, .cart-add-address select,
.cart-edit-address select, .cart-signup select, .guest-signup select, .cart-address select, .cart-delivery select, .payment-method-choice select, .create-customer-address select,
.edit-customer-address select, .contact-us select {
  background-color: #faf6f4;
}

.customer-signup textarea.form-control, .form-signup textarea.form-control, .cart-add-address textarea.form-control,
.cart-edit-address textarea.form-control, .cart-signup textarea.form-control, .guest-signup textarea.form-control, .cart-address textarea.form-control, .cart-delivery textarea.form-control, .payment-method-choice textarea.form-control, .create-customer-address textarea.form-control,
.edit-customer-address textarea.form-control, .contact-us textarea.form-control {
  min-height: 120px;
}

.customer-signup .checkbox label, .form-signup .checkbox label, .cart-add-address .checkbox label,
.cart-edit-address .checkbox label, .cart-signup .checkbox label, .guest-signup .checkbox label, .cart-address .checkbox label, .cart-delivery .checkbox label, .payment-method-choice .checkbox label, .create-customer-address .checkbox label,
.edit-customer-address .checkbox label, .contact-us .checkbox label {
  position: relative;
  top: 3px;
}

html {
  padding: 0;
}

html, body, #wrapper {
  width: 100%;
}

#wrapper {
  transition: all 0.5s ease;
}

@media (min-width: 1400px) {
  .container {
    width: 1280px;
  }
}

.voucher-badge {
  width: 20px !important;
  height: 20px !important;
  background: #FF0033;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: inline-block;
}

.padding-0, .no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.col-tiny {
  padding-left: 2px;
  padding-right: 2px;
}

@media (max-width: 479px) and (orientation: portrait) {
  .product-tiny {
    width: 50% !important;
  }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {
  .product-tiny {
    width: 50% !important;
    height: 480px !important;
  }
}

@media (min-width: 641px) and (max-width: 768px) and (orientation: landscape) {
  .product-tiny {
    width: 50% !important;
    height: 530px;
  }
}

@media (min-width: 480px) and (max-width: 768px) and (orientation: portrait) {
  .product-tiny {
    width: 50% !important;
  }
}

.inline {
  display: inline;
}

.img-cont {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.img-cont:before {
  content: '';
  display: block;
}

.red {
  color: #ff0134;
}

h1 .more {
  float: right;
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding-top: 25px;
}

h1 .more:after {
  content: '\f101';
  font-family: FontAwesome;
  font-size: 14px;
  margin-left: 5px;
}

h3 {
  font: normal 24px "asap-bold-webfont", sans-serif;
}

h3 .more {
  float: right;
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding-top: 10px;
}

h3 .more:after {
  content: '\f101';
  font-family: FontAwesome;
  font-size: 14px;
  margin-left: 5px;
}

ul.list, .header-links {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 767px) {
  .title-md-inline {
    display: block;
  }
}

#menuBtn {
  position: absolute;
  top: 45%;
  right: 30px;
  font-size: 38px;
}

#banner {
  position: relative;
}

#banner .slick-slider {
  margin: 0;
  transition: all 0.5s ease-in-out;
}

#banner .slides .slide .image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 28%;
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .slick-dots {
  width: auto;
}

#banner .slick-dots li {
  width: auto !important;
  height: auto !important;
}

#banner .slick-dots li.slick-active button, #banner .slick-dots li button:hover {
  background-color: white;
  opacity: 1;
}

#banner .slick-dots li button {
  background-color: rgba(255, 255, 255, 0.5);
}

#banner .slick-arrow:before {
  font-size: 42px;
}

#inner-banner .slide {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media all and (min-width: 480px) and (max-width: 768px) {
  #inner-banner .slide {
    height: 250px;
  }
}

@media all and (max-width: 480px) {
  #inner-banner .slide {
    height: 150px;
  }
}

.breadcrumb {
  display: none;
  margin: 0;
  padding: 0 0 20px;
  background-color: transparent;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

@media (min-width: 768px) {
  .breadcrumb {
    display: block;
  }
}

.breadcrumb a {
  color: #62534f;
  padding: 0;
}

.breadcrumb a:after {
  content: '\f105';
  font-family: FontAwesome;
  font-size: 16px;
  margin: 0 5px;
}

.breadcrumb a:last-child:after {
  content: none;
}

#content {
  background-color: #FFF5EB;
  position: relative;
  z-index: 1;
}

#content.journal-page {
  background-color: white;
}

#content > .listing, #content > .home {
  background-image: url("../../img/uno-shoppe-v3/home_bg.jpg");
  background-position: center;
}

#content .group-type-title {
  background-color: #62534f;
  color: #ffffff;
  padding: 15px 25px;
  font: normal 11px "asap-bold-webfont", sans-serif;
  text-transform: uppercase;
  border-bottom: 1px solid #2f2f2b;
}

#content .group-type-title img {
  margin-right: 10px;
}

#content #top-content .top-content-block {
  border-bottom: 1px solid #2f2f2b;
  border-right: 1px solid #eaeaea;
  background-color: #12ca72;
  padding: 20px 0;
  color: #ffb700;
  font: normal 13px "asap-bold-webfont", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

#content #top-content .top-content-block .col-image {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

@media (max-width: 1200px) {
  #content #top-content .top-content-block .col-image {
    display: none;
  }
}

#content #top-content .top-content-block .col-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.3;
}

#content .page-content {
  padding-bottom: 30px;
  /*h1, h2, h3 {
      color: $darkblue;
      padding: 15px 0;
      margin: 15px 0 0;
    }
    h2 {
      font-size: $huge-font;
    }
    p {
      color: $fontblack;
      font-size: $small-font;
      text-align: justify;
    }*/
}

#content .page-content img {
  /*max-width: 100%;*/
  /*display: block;*/
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .content-main > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .content-sub > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini .title-cont {
  position: relative;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 767px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide .date {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

#newsletter {
  width: 100%;
  cursor: pointer;
  text-align: left;
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  background-color: #005F90;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

#newsletter .col {
  padding: 0;
}

#newsletter .col img {
  display: inline-block;
  width: auto;
}

#newsletter h3.title {
  color: #1ca7e0 !important;
  font-size: 20px;
  margin: 0 0 5px !important;
  padding: 0;
}

@media (max-width: 991px) {
  #newsletter h3.title {
    font-size: 17px;
  }
}

#newsletter .link {
  color: white;
  font-size: 13px;
  display: none;
  position: relative;
}

#newsletter .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#newsletter .form-group {
  display: none;
}

#newsletter .form-group .form-control {
  width: 100%;
  height: 34px;
  font-weight: normal;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#newsletter .form-group .form-control-feedback {
  width: auto;
  height: auto;
  font-size: 14px;
  margin: 10px;
}

.a-banner {
  transition: all 0.5s ease;
}

.a-banner .slick-dots {
  width: auto;
}

.a-banner .slick-dots li {
  width: auto !important;
  height: auto !important;
}

.a-banner .slick-dots li.slick-active button, .a-banner .slick-dots li button:hover {
  background-color: #ffb700;
  opacity: 1;
}

.a-banner .slick-dots li button {
  background-color: #887975;
  width: 10px !important;
  height: 10px !important;
}

.a-banner img {
  margin: auto;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

#social-icon {
  margin-bottom: 5px;
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

#social-icon ul li {
  font-size: 16px;
  display: inline-block;
}

#social-icon ul li a {
  color: #F3F3F3;
  padding: 0 5px;
}

#social-icon ul li a img {
  max-height: 18px;
  vertical-align: bottom;
}

/*inner page*/
#inner {
  padding-top: 30px;
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  /*transition: height 0.3s;*/
}

.grocer-check-location {
  background-color: #ffb700;
  border-radius: 5px;
}

.grocer-check-location .text {
  padding: 30px;
  padding-left: 60px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .grocer-check-location .text {
    padding: 30px 15px;
  }
}

.grocer-check-location .text:before {
  content: '\f041';
  font-family: FontAwesome;
  font-size: 32px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 30px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (max-width: 767px) {
  .grocer-check-location .text:before {
    content: none;
  }
}

.grocer-check-location .text h3 {
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

.grocer-check-location .text p {
  margin: 0;
}

.grocer-check-location + .product-list {
  margin-top: 50px;
}

.news-events {
  background-color: #ffffff;
  padding: 30px;
}

@media (max-width: 767px) {
  .news-events {
    margin-top: 30px;
  }
}

.news-events h3 {
  color: #62534f;
  margin: 0 0 15px;
}

.news-events .news + .news {
  border-top: 1px solid #f0e5dc;
}

.news-events .date {
  padding-right: 40px;
  color: #887975;
  font: normal 12px "asap-regular-webfont", sans-serif;
}

@media (max-width: 767px) {
  .news-events .date {
    margin-top: 10px;
  }
}

.news-events .desc {
  white-space: nowrap;
  padding: 10px 0;
}

.news-events .desc a {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.steps .step {
  display: table;
  position: relative;
}

.steps .step + .step {
  min-height: 70px;
}

@media (max-width: 767px) {
  .steps .step + .step {
    margin-top: 15px;
  }
}

.steps .step + .step:before {
  content: '';
  background-size: contain;
  background-image: url("../../img/uno-shoppe-v3/icon_arrow.png");
  background-position: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 58px;
}

@media (max-width: 767px) {
  .steps .step + .step:before {
    content: none;
  }
}

.steps .icon {
  display: table-cell;
  vertical-align: middle;
  padding-right: 15px;
  min-width: 50px;
}

.steps .content {
  display: table-cell;
  vertical-align: middle;
}

.steps h4 {
  margin-bottom: 5px;
}

.steps p {
  margin: 0;
}

.social {
  margin-bottom: 30px;
}

.social h4 {
  color: #eadcd3;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin: 0 0 5px;
}

.social a {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.5s ease;
}

.social a:hover {
  color: #ffffff;
}

.social a + a {
  margin-left: 5px;
}

.social a .fa {
  color: #887975;
  font-size: 20px;
}

@media (max-width: 767px) {
  .content-bottom {
    text-align: center;
  }
}

.content-bottom .pagination {
  float: right;
}

@media (max-width: 767px) {
  .content-bottom .pagination {
    float: none;
  }
}

.pagination > li {
  display: inline-block;
  margin: 0 2px;
}

.pagination > li a,
.pagination > li span {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  float: none;
  background-color: white;
  padding: 0;
  text-align: center;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  border: 0;
  transition: all 0.5s ease;
  border-radius: 5px;
}

.pagination > li a:hover,
.pagination > li span:hover {
  background-color: #eadcd3 !important;
}

.pagination > li.active a, .pagination > li.active span {
  background-color: #eadcd3 !important;
}

@media (max-width: 390px) {
  .col-xxs-12 {
    width: 100%;
  }
}

.title-midline {
  position: relative;
  overflow: hidden;
}

.title-midline:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  right: 0;
  border-top: 1px solid #f3a200;
}

.title-midline span {
  display: inline-block;
  padding-right: 30px;
  background-color: #fff5ea;
  z-index: 1;
  position: relative;
}

.tnc-panel .title-midline {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin-bottom: 15px;
}

.tnc-panel ul {
  list-style: none;
  padding: 0;
}

.tnc-panel li {
  position: relative;
  padding: 0 0 0 30px;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.tnc-panel li:before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0;
}

.tags {
  margin: 0 -2.5px;
}

.tags .tag {
  display: inline-block;
  vertical-align: middle;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  padding: 5px 30px 5px 5px;
  position: relative;
  border-radius: 5px;
  background-color: #eadcd3;
  margin: 2.5px;
  transition: all 0.5s ease;
}

.tags .tag:focus, .tags .tag:active, .tags .tag:hover {
  color: #ffffff;
  background-color: #12ca72;
}

.tags .tag:after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 8px;
  border-radius: 50%;
  margin-top: -2.5px;
  background-color: white;
}

.tags + .journals {
  margin-top: 30px;
}

.journals .journal {
  padding: 0 10px;
}

.journals .journal > a {
  display: block;
}

.journals .journal img {
  width: 100%;
  transition: opacity .3s;
}

.journals .journal:hover img {
  opacity: 0.7;
}

.journals .journal .info {
  padding: 30px;
  background-color: white;
  text-align: center;
}

.journals .journal p {
  margin: 0 0 10px;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.journals .journal h3 {
  margin: 0 0 15px;
  color: #62534f;
  font: normal 18px "asap-bold-webfont", sans-serif;
}

.journals .journal .btn, .journals .journal .product .col-btn button, .product .col-btn .journals .journal button, .journals .journal .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .journals .journal button, .journals .journal .suggestion-popup .col-btn button, .suggestion-popup .col-btn .journals .journal button {
  border: 0;
}

.journal-page .date {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.journal-page .article p {
  line-height: 2;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.journal-page .article > img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 0 30px;
}

.journal-page .related-articles {
  padding-top: 60px;
}

.message-list .message-panel {
  background-color: white;
}

.message-list .title {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
}

@media (max-width: 480px) {
  .message-list .row-xs-height .col-height {
    width: 100%;
    display: block;
  }
}

.message-list .count {
  padding: 2px 5px;
  border-radius: 2px;
  color: white;
  background-color: #ff0134;
  margin-left: 5px;
}

.message-list .btn, .message-list .product .col-btn button, .product .col-btn .message-list button, .message-list .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .message-list button, .message-list .suggestion-popup .col-btn button, .suggestion-popup .col-btn .message-list button {
  height: 30px;
  line-height: 30px;
}

@media (max-width: 480px) {
  .message-list .btn, .message-list .product .col-btn button, .product .col-btn .message-list button, .message-list .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .message-list button, .message-list .suggestion-popup .col-btn button, .suggestion-popup .col-btn .message-list button {
    margin: 10px 0 0;
  }
}

.message-popup {
  position: relative;
  padding: 30px;
}

.message-popup .mfp-close {
  right: 20px;
  top: 30px;
}

.message-popup textarea {
  width: 100%;
  height: 80px;
  background-color: #faf6f4;
  border: 1px solid #f0e5dc;
  border-radius: 5px;
}

.message-popup label[for="upload-image"] {
  border: 1px solid #f0e5dc;
  background-color: white;
  line-height: 40px;
  height: 40px;
  padding: 0 15px;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  color: #62534f;
}

.message-popup .form-action {
  margin: 4px 0 40px;
}

.message-popup .send-btn {
  float: right;
}

.message-popup .upload-display {
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin-left: 5px;
}

.message-popup .messages {
  background-color: #fdf1d9;
  max-height: 300px;
}

.message-popup .messages > .clearfix, .message-popup .messages > .product-list, .message-popup .product-list .messages > h1, .product-list .message-popup .messages > h1, .message-popup .weekly-offers .messages > .weekly-offers-product-list, .weekly-offers .message-popup .messages > .weekly-offers-product-list, .message-popup .filter-list .panel-group .messages > .panel-sldier, .filter-list .panel-group .message-popup .messages > .panel-sldier, .message-popup .messages > .shoppie-total, .message-popup .product-reviews .messages > h1.subtitle, .product-reviews .message-popup .messages > h1.subtitle, .message-popup .customer-address .messages > .form-group, .customer-address .message-popup .messages > .form-group, .message-popup .customer-profile .messages > .form-group, .customer-profile .message-popup .messages > .form-group, .message-popup .messages > .list-panel, .message-popup .list-panel .messages > .list-header, .list-panel .message-popup .messages > .list-header, .message-popup .list-panel .messages > .panel, .list-panel .message-popup .messages > .panel, .message-popup .messages > .widget-bar, .message-popup .messages > .header-logo, .message-popup .messages > .message {
  padding: 20px;
}

.message-popup .messages h3 {
  text-align: center;
  color: #62534f;
  font: normal 15px "asap-bold-webfont", sans-serif;
  margin: 0 0 20px;
  line-height: 1;
}

.message-popup .messages .message {
  padding: 0;
  background-color: transparent;
  width: 60%;
}

.message-popup .messages .message + .message {
  padding-top: 15px;
}

.message-popup .messages .message span {
  padding: 10px;
  border: 1px solid #f0e5dc;
  line-height: 1.6;
  border-radius: 5px;
  background-color: white;
  display: inline-block;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.message-popup .messages .message .date {
  padding: 2px 10px;
  color: #62534f;
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.message-popup .messages .message.self {
  float: right;
}

.message-popup .messages .message.self span {
  background-color: #eadcd3;
  float: right;
  clear: both;
}

.message-popup .messages .message.self .date {
  float: right;
  clear: both;
}

.message-popup .messages .message img {
  max-width: 100%;
  margin-top: 5px;
}

.delivery-method .col-grid {
  text-align: center;
}

.delivery-method img {
  margin: auto auto 50px;
}

.delivery-method p {
  margin: 0;
}

.delivery-method .btn, .delivery-method .product .col-btn button, .product .col-btn .delivery-method button, .delivery-method .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .delivery-method button, .delivery-method .suggestion-popup .col-btn button, .suggestion-popup .col-btn .delivery-method button {
  display: inline-block;
  line-height: 40px;
  margin-top: 20px;
}

.errors {
  color: #ff0134;
  border: 1px solid #ff0134;
  border-radius: 5px;
  padding: 30px;
  background-color: rgba(255, 1, 52, 0.1);
}

.errors p {
  color: #ff0134;
  margin-bottom: 5px;
}

.errors ol {
  padding: 0 15px;
  margin-bottom: 0;
}

.errors li {
  line-height: 1.6;
  padding-left: 15px;
}

.jssocials-shares .jssocials-share {
  display: inline-block;
  height: 20px;
  vertical-align: middle;
}

.jssocials-shares .jssocials-share + .jssocials-share {
  margin-left: 5px;
}

.datepicker-dropdown {
  z-index: 12 !important;
}

.product-listing-sort .option select {
  padding: 2px 30px 2px 15px;
  height: 28px;
  min-width: 130px;
}

.inner-content, .cms-page {
  padding: 30px;
  background-color: white;
  border-radius: 5px;
}

.inner-content + .inner-content {
  margin-top: 30px;
}

.newsletter-popup,
.error-popup,
.location-popup {
  padding: 30px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.newsletter-popup h3,
.error-popup h3,
.location-popup h3 {
  margin-bottom: 15px;
}

.newsletter-popup .form-control,
.location-popup .form-control {
  border: 0;
  background-color: #f2f2f2;
  border-radius: 5px !important;
  transition: background 0.5s ease;
  float: none;
}

.newsletter-popup .form-control[readonly],
.location-popup .form-control[readonly] {
  background-color: white;
  cursor: auto;
}

.newsletter-popup label,
.location-popup label {
  font: normal 15px "asap-bold-webfont", sans-serif;
}

.contact-us .description {
  font: normal 14px "asap-regular-webfont", sans-serif;
  line-height: 1.6;
}

.contact-us .description a {
  color: #f3a200;
  font: normal 15px "asap-bold-webfont", sans-serif;
  text-decoration: underline;
}

.contact-us .description b {
  font: normal 15px "asap-bold-webfont", sans-serif;
  font-size: 14px;
}

.contact-us .contact-us-gmap iframe {
  width: 100%;
  height: 300px;
}

.contact-us textarea {
  max-width: 100%;
}

.contact-us-form-location {
  margin-top: 30px;
}

.contact-us-form-location .input-group input.form-control {
  max-width: 100%;
  border-radius: 5px;
}

.contact-us-form-location .input-group .btn, .contact-us-form-location .input-group .product .col-btn button, .product .col-btn .contact-us-form-location .input-group button, .contact-us-form-location .input-group .product-detail .product-info .col-btn button, .product-detail .product-info .col-btn .contact-us-form-location .input-group button, .contact-us-form-location .input-group .suggestion-popup .col-btn button, .suggestion-popup .col-btn .contact-us-form-location .input-group button {
  border-radius: 5px;
  margin-left: 5px;
}

.contact-us-outlet-list {
  margin-top: 30px;
}

.contact-us-outlet-list h4 {
  margin-bottom: 5px;
}

.contact-us-form-enquiry {
  font: normal 14px "asap-regular-webfont", sans-serif;
}

.contact-us-form-enquiry label.btn {
  padding: 0 30px;
  color: #ffffff;
  line-height: 40px;
}

.contact-us-form-enquiry .upload-display {
  color: #62534f;
  margin-left: 5px;
  display: inline-block;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .contact-us-form-enquiry .upload-display {
    display: inline-block;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
}

.contact-us-form-enquiry textarea {
  max-width: 100% !important;
}

.links-float {
  position: fixed;
  bottom: -500px;
  left: 0;
  padding: 10px;
  background-color: white;
  z-index: 100;
  border-radius: 0 5px 5px 0;
  transition: all 0.5s ease;
}

.links-float.active {
  bottom: 0;
}

.links-float button {
  top: -40px;
  display: block;
  position: absolute;
}

.links-float ul {
  max-height: 480px;
  overflow: auto;
  padding: 0;
  margin: 0;
  list-style: none;
}

.links-float li {
  display: block;
}

.links-float a {
  display: block;
  padding: 5px 10px;
}

.invalid input:required:invalid {
  background: #BE4C54;
}

.invalid input:required:valid {
  background: #17D654;
}

input {
  display: block;
  margin-bottom: 10px;
}

.form-control.error {
  border: 1px solid red;
}

span.error {
  color: red;
  display: block;
  margin-bottom: 15px;
}

.col-checkbox span.error {
  position: absolute;
  top: 40px;
  white-space: nowrap;
}

.radio-inline .iradio_flat-orange {
  margin-right: 5px;
}

.loader-molpay,
.loader-molpay:before,
.loader-molpay:after {
  border-radius: 50%;
}

.loader-molpay:before,
.loader-molpay:after {
  position: absolute;
  content: '';
}

.loader-molpay:before {
  width: 5.2em;
  height: 10.2em;
  background: #0dc5c1;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}

.loader-molpay {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.loader-molpay:after {
  width: 5.2em;
  height: 10.2em;
  background: #0dc5c1;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-signup .form-control {
  background: #eee !important;
}

.form-signup .option select {
  background: #eee !important;
}

.form-signup .select2-selection__rendered {
  background: #eee !important;
}

/*# sourceMappingURL=app.css.map */
